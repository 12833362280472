import {
  Panel,
  PanelType,
  Stack,
  Dropdown,
  Icon,
  DatePicker,
  DayOfWeek,
  MaskedTextField,
  Text,
  TextField,
  PrimaryButton,
  DefaultButton,
  Separator,
  DetailsList,
  TooltipHost,
  FontIcon,
  IconButton,
  MessageBarType,
  SelectionMode,
  ChoiceGroup,
  IChoiceGroupOption,
  SearchBox,
  DetailsListLayoutMode,
  getTheme,
} from "@fluentui/react";
import {
  DetailsRow,
  DetailsRowCheck,
  IDetailsListProps,
  IDetailsRowStyles,
  Selection,
} from "@fluentui/react/lib/DetailsList";
import { useAtomValue, useSetAtom } from "jotai";
import React, {
  useImperativeHandle,
  useContext,
  useState,
  useEffect,
} from "react";
import { useConst } from "@fluentui/react-hooks";
import {
  isInProgressAtom,
  errorMessageAtom,
  successMessageAtom,
  messageBarAtom,
} from "../../atoms/messageBarAtoms";
import {
  IAsset,
  IAssetStatuses,
  IScheduledAction,
  getScheduledUpdateAssetStatus,
  IChatCommand,
  IScheduleActionRequest,
  scheduleUpdateAssetStatus,
  IWorkOrderAssignmentResponse,
  cancelScheduledUpdateAssetStatus,
  getAssetStatuses,
  IWorkflow,
  getWorkflows,
  IPagedCollection,
} from "../../services/assetServices";
import AppContext from "../AppContext";
import { createChatMessage } from "../ChatCommandDialog";
import { chatCommandContentsAtom } from "../../atoms/chatCommandAtoms";

export interface IAssetSchedulePanelProps {
  isSchedulePanelOpen: boolean;
  hideSchedulePanel: () => void;
}

export interface IAssetSchedulePanelRefType {
  fetchScheduledAction: (selectedAsset: IAsset, action?: string) => void;
}

const AssetSchedulePanel: React.ForwardRefRenderFunction<
  IAssetSchedulePanelRefType,
  IAssetSchedulePanelProps
> = (props, forwardedRef) => {
  useImperativeHandle(forwardedRef, () => ({ fetchScheduledAction }));

  const context = useContext(AppContext);

  const [showScheduleChatHeader, setShowScheduleChatHeader] = useState(true);
  const [scheduledActions, setScheduledActions] =
    useState<IScheduledAction[]>();
  const [actionType, setActionType] = useState<string | undefined>("status");
  const [scheduleAssetName, setScheduleAssetName] = useState<string>();
  const [scheduleAssetCode, setScheduleAssetCode] = useState<string>();
  const [scheduleDate, setScheduleDate] = useState<Date | undefined>();
  const [scheduleTime, setScheduleTime] = useState<string>();
  const [assetStatuses, setAssetStatuses] = useState<IAssetStatuses[]>();
  const [workflows, setWorkflows] = useState<IWorkflow[]>();

  const [scheduleStatus, setScheduleStatus] = useState(0);
  const [scheduleWorkflowId, setScheduleWorkflowId] = useState(0);
  const [scheduleDescription, setScheduleDescription] = useState("");

  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setMessageBar = useSetAtom(messageBarAtom);

  const chatCommandContents = useAtomValue(chatCommandContentsAtom);

  const today = useConst(new Date(Date.now()));

  const buttonStyles = { root: { marginRight: 8 } };

  const fetchScheduledAction = async (
    selectedAsset: IAsset,
    action?: string
  ) => {
    const abortController = new AbortController();
    try {
      setIsInProgress(true);
      const assetStatuses = await getAssetStatuses(abortController);
      setAssetStatuses(assetStatuses);
      setScheduleStatus(assetStatuses[0].id);
      const scheduledActions = await getScheduledUpdateAssetStatus(
        abortController,
        context.selectedItem.id
      );
      setScheduledActions(
        scheduledActions
          ? scheduledActions.map((sa: any) => ({
              ...sa,
              createdAt: new Date(sa.createdAt + "Z").toLocaleString(),
              scheduledTime: new Date(sa.scheduledTime + "Z").toLocaleString(),
            }))
          : []
      );
      const workflows = await getWorkflows(abortController, 10, 1);
      setWorkflows(workflows.items);

      setScheduleAssetName(selectedAsset?.name);
      setShowScheduleChatHeader(false);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
  };

  useEffect(() => {
    const convertChatCommand = async () => {
      if (chatCommandContents.action === "schedule") {
        const command = chatCommandContents;
        if (command.action === "schedule" && command.entityType === "asset") {
          const time = command.others["time" as keyof IChatCommand] as string;
          const [dateStr, timeStr] = time.split(" ");
          const [year, month, day] = dateStr.split("/");
          const [hours, mins] = timeStr.replace(/[a-p]m/, "").split(":");
          const adjHours = +hours + (timeStr.includes("pm") ? 12 : 0);
          setScheduleDate(new Date(dateStr));
          setScheduleTime(
            new Date(
              `${year}-${month}-${day}T${adjHours}:${mins}:00`
            ).toTimeString()
          );
          const code = command["code" as keyof IChatCommand] as string;
          setScheduleAssetCode(code);
          const statusStr = command.others[
            "status" as keyof IChatCommand
          ] as string;
          const abortController = new AbortController();
          const assetStatuses = await getAssetStatuses(abortController);
          setAssetStatuses(assetStatuses);

          const assetStatusId = assetStatuses?.find(
            (as) => as.name === statusStr
          )?.id;
          if (assetStatusId) {
            setScheduleStatus(assetStatusId);
          }
        }
      }
      //setScheduleAssetName(selectedAsset?.name)
      setShowScheduleChatHeader(true);
    };

    convertChatCommand();
  }, [chatCommandContents]);

  const handleSave = () => {
    const newDate = scheduleDate ?? new Date();
    if (scheduleStatus === 0) {
      setErrorMessage("Please select status");
      return;
    }
    if (scheduleTime) {
      const hour = Number.parseInt(scheduleTime.split(":")[0]);
      const min = Number.parseInt(scheduleTime.split(":")[1].split(" ")[0]);
      newDate.setHours(hour);
      newDate.setMinutes(min);
      const newScheduledUpdate: IScheduleActionRequest = {
        time: newDate.toUTCString(),
        actionType: actionType === "status" ? 0 : 1,
        statusId: actionType === "status" ? scheduleStatus : undefined,
        workflowId: actionType === "workflow" ? scheduleWorkflowId : undefined,
        description: scheduleDescription,
      };
      const abortController = new AbortController();
      scheduleUpdateAssetStatus(
        abortController,
        newScheduledUpdate,
        scheduleAssetCode ? undefined : context.selectedItem.id,
        scheduleAssetCode
      )
        .then((data: IWorkOrderAssignmentResponse) => {
          setSuccessMessage(`Scheduled [${data.workOrderAssignmentId}]`);
        })
        .catch((error: any) => setErrorMessage(error.message));
      props.hideSchedulePanel();
    } else {
      setErrorMessage("Please select Date and time");
    }
  };

  const options: IChoiceGroupOption[] = [
    {
      key: "status",
      text: "",
      onRenderLabel: (props) => (
        <Stack
          horizontal
          onClick={() => {
            if (props) {
              props.checked = true;
            }
          }}
        >
          <Icon
            style={{ marginRight: "8px" }}
            iconName="StatusCircleErrorX"
            aria-hidden="true"
            title="Status change"
          />
          <span>Select the status</span>
          <Dropdown
            styles={{ root: { width: 200, marginTop: -5, marginLeft: 15 } }}
            disabled={actionType !== "status"}
            options={
              assetStatuses?.map((as) => ({
                key: as.id,
                text: as.name,
                data: { icon: as.icon },
              })) ?? []
            }
            onRenderOption={(option): JSX.Element =>
              option ? (
                <div>
                  {option.data && option.data.icon && (
                    <Icon
                      style={{ marginRight: "8px" }}
                      iconName={option.data.icon}
                      aria-hidden="true"
                      title={option.data.icon}
                    />
                  )}
                  <span>{option.text}</span>
                </div>
              ) : (
                <></>
              )
            }
            onRenderTitle={(options): JSX.Element =>
              options && options[0] ? (
                <div>
                  {options[0].data && options[0].data.icon && (
                    <Icon
                      style={{ marginRight: "8px" }}
                      iconName={options[0].data.icon}
                      aria-hidden="true"
                      title={options[0].data.icon}
                    />
                  )}
                  <span>{options[0].text}</span>
                </div>
              ) : (
                <></>
              )
            }
            onChange={(event, option: any, index) =>
              setScheduleStatus(Number.parseInt(option.key.toString()))
            }
            defaultSelectedKey={scheduleStatus}
          />
        </Stack>
      ),
    },
    {
      key: "workflow",
      text: "Workflow",
    },
  ];

  const _selection = new Selection({
    onSelectionChanged: () => {
      if (_selection.getSelectedCount() > 0) {
        setScheduleWorkflowId(
          Number.parseInt(
            (_selection.getSelection()[0] as IWorkflow).id.toString()
          )
        );
      }
    },
  });

  const theme = getTheme();
  const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = { backgroundColor: theme.palette.neutralLighter };
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <Panel
      isLightDismiss
      isOpen={
        props.isSchedulePanelOpen || chatCommandContents.action === "schedule"
      }
      isBlocking={false}
      onDismiss={props.hideSchedulePanel}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Select a status and a time in future"
      isFooterAtBottom={true}
    >
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack
          styles={{ root: { display: showScheduleChatHeader ? "" : "none" } }}
        >
          <Text variant="medium">Chat: </Text>
          <Text variant="small">{createChatMessage(chatCommandContents)}</Text>
        </Stack>
        <Text variant="small">Selected asset: </Text>
        <Text variant="large">{scheduleAssetName}</Text>
        <Text variant="small">Code:</Text>
        <Text variant="large">{scheduleAssetCode}</Text>

        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <DatePicker
            style={{ width: 175 }}
            firstDayOfWeek={DayOfWeek.Sunday}
            placeholder="Select a date..."
            ariaLabel="Select a date"
            minDate={today}
            onSelectDate={
              setScheduleDate as (date: Date | null | undefined) => void
            }
            value={scheduleDate}
          />
          <MaskedTextField
            mask="99:99"
            value={scheduleTime}
            onChange={(e, newValue) => {
              setScheduleTime(newValue ?? "00:00 am");
            }}
            styles={{ fieldGroup: { width: 55 } }}
          />
        </Stack>

        <ChoiceGroup
          defaultSelectedKey={"status"}
          onChange={(ev, option: IChoiceGroupOption | undefined) => {
            setActionType(option?.key);
          }}
          options={options}
        />
        <SearchBox
          styles={{ root: { width: 200 } }}
          placeholder="Search workflow"
          onSearch={(newValue) => {
            const abortController = new AbortController();
            getWorkflows(abortController, 5, 1, undefined, newValue).then(
              (data: IPagedCollection<IWorkflow>) => {
                setWorkflows(data.items);
              }
            );
          }}
          onClear={() => setWorkflows([])}
          disabled={actionType !== "workflow"}
        />
        <Stack styles={{ root: { width: 355 } }}>
          <DetailsList
            items={workflows ?? []}
            selectionZoneProps={
              actionType === "workflow"
                ? undefined
                : { selectionMode: SelectionMode.none }
            }
            columns={[
              {
                key: "id",
                name: "id",
                minWidth: 25,
                fieldName: "id",
                isResizable: true,
              },
              {
                key: "name",
                name: "name",
                minWidth: 200,
                fieldName: "name",
                isResizable: true,
              },
            ]}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionMode={SelectionMode.single}
            selection={_selection}
            compact={true}
            onRenderRow={actionType === "workflow" ? undefined : _onRenderRow}
            onRenderCheckbox={
              actionType === "workflow"
                ? undefined
                : (props) =>
                    props?.theme && props?.checked ? (
                      <Icon iconName="CheckMark" />
                    ) : (
                      <></>
                    )
            }
          />
        </Stack>
        <TextField
          label="Description (optional)"
          multiline
          rows={3}
          onChange={(e, newValue) => setScheduleDescription(newValue ?? "")}
        />
        <Stack horizontal>
          <PrimaryButton onClick={handleSave} styles={buttonStyles}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={props.hideSchedulePanel}>
            Cancel
          </DefaultButton>
        </Stack>
        <Separator>Existing schedules</Separator>
        <DetailsList
          items={scheduledActions ?? []}
          columns={[
            {
              key: "createdAt",
              name: "Created at",
              fieldName: "createdAt",
              minWidth: 120,
            },
            {
              key: "time",
              name: "Scheduled time",
              fieldName: "scheduledTime",
              minWidth: 120,
              maxWidth: 120,
            },
            {
              key: "assetStatus",
              name: "Status",
              fieldName: "assetStatusId",
              minWidth: 70,
              maxWidth: 70,
              onRender: (item, index, column) => {
                const assetStatus = assetStatuses?.find(
                  (is) => is.id === item.assetStatusId
                );
                return (
                  <Stack
                    horizontal
                    style={{
                      borderBottomColor: `${assetStatus?.color}`,
                      borderBlockStyle: "solid",
                      borderTopStyle: "none",
                    }}
                  >
                    <Stack.Item align="start">
                      <span style={{ display: "flex", justifyContent: "left" }}>
                        {assetStatus?.code}
                      </span>
                    </Stack.Item>
                    <Stack.Item align="stretch" grow={1}>
                      <span style={{ width: "25px" }}></span>{" "}
                    </Stack.Item>
                    <Stack.Item align="end">
                      <TooltipHost content={assetStatus?.name}>
                        <FontIcon iconName={assetStatus?.icon} />
                      </TooltipHost>
                    </Stack.Item>
                  </Stack>
                );
              },
            },
            {
              key: "actionStatus",
              name: "Result (times in UTC)",
              fieldName: "actionStatus",
              minWidth: 160,
              maxWidth: 160,
            },
            {
              key: "action",
              name: "Action",
              fieldName: "",
              minWidth: 50,
              maxWidth: 50,
              onRender: (item, index, column) => {
                return (
                  <IconButton
                    iconProps={{ iconName: "Cancel" }}
                    onClick={() => {
                      var abortController = new AbortController();
                      cancelScheduledUpdateAssetStatus(abortController, item.id)
                        .then((data: string) => {
                          if (data.startsWith("WRN")) {
                            setMessageBar(data, MessageBarType.warning);
                          } else {
                            setSuccessMessage(data);
                          }
                        })
                        .catch((error: any) => setErrorMessage(error.message));
                    }}
                  />
                );
              },
            },
          ]}
          compact={true}
          selectionMode={SelectionMode.none}
        />
      </Stack>
    </Panel>
  );
};

export default React.forwardRef(AssetSchedulePanel);

import { FontWeights, getTheme, mergeStyleSets } from "@fluentui/react";

const theme = getTheme();

const ModalContentStyles = mergeStyleSets({
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "0px 14px 0px 14px",
    },
  ]
});

export default ModalContentStyles;
import React from "react";
export interface IDetailsListBasicExampleItem {
  id: number;
  uuid: string;
  code?: string;
  name?: string;
}

const defaultItem: IDetailsListBasicExampleItem = { id: 0, uuid: '', code: '', name: '' };

export interface IMessageBarAction {
  actionLabel: string;
  action: () => {};
}

export interface IMiniChartOptions {
  xAxisFormat: (input: string) => string;
}

const AppContext = React.createContext({
  selectedItem: defaultItem,
  setSelectedItem: (item: IDetailsListBasicExampleItem) => { },
  isProfileDialogOpen: false,
  setIsProfileDialogOpen: (flag: boolean) => { },
  actions: new Array<IMessageBarAction>(),
  setSelectedTab: (selectedTab: string, miniChartData?: Map<string, number>, miniChartOptions?: IMiniChartOptions) => { },
  selectedTab: "",
  miniChartData: new Map<string, number>(),
  miniChartOptions: { xAxisFormat: (x: string) => x },
});

export default AppContext;

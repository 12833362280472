import { getTheme, IButtonStyles } from "@fluentui/react";

const theme = getTheme();

const ModalIconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default ModalIconButtonStyles;
import { DefaultButton, IconButton, IIconProps, Modal, PrimaryButton, Stack } from '@fluentui/react'
import ModalContentStyles from './styles/ModalContentStyles';
import ModalIconButtonStyles from './styles/ModalIconButtonStyles';

export interface IConfirmDialogParams {
  message: string;
  isModalOpen: boolean;
  hideModal: any;
  onYesClick: any;
}

const ConfirmDialog = (params: IConfirmDialogParams) => {

  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const stackTokens = { childrenGap: 50 };

  return (
    <Modal
      isOpen={params.isModalOpen}
      onDismiss={params.hideModal}
      isBlocking={false}
    >
      {" "}
      <div className={ModalContentStyles.header}>
        <span>Are you sure?</span>
        <IconButton
          styles={ModalIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={params.hideModal}
        />
      </div>
      <Stack>
        <Stack.Item align="center">
          <p>{params.message}</p>
          <Stack horizontal tokens={stackTokens}>
            <PrimaryButton onClick={params.onYesClick}>
              Yes
            </PrimaryButton>
            <DefaultButton onClick={params.hideModal}>No</DefaultButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  )
}

export default ConfirmDialog
import { DatePicker, IconButton, IContextualMenuItem, IContextualMenuProps, IDatePicker, Stack } from '@fluentui/react';
import { useRef, useState } from 'react'

interface IHeaderWithFilterParams {
  columnName: string;
  columnDbName: string;
  type: string;
  onFilterSelected: (filterStr: string) => void;
  onFilterCleared: () => void;
}

const HeaderWithFilter = (params: IHeaderWithFilterParams) => {
  const datePicker = useRef<IDatePicker>(null);
  const [operation, setOperation] = useState<string>();
  const [isFilterSet, setIsFilterSet] = useState(false);

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'Eq',
        text: 'Equal',
        iconProps: { iconName: 'CalculatorEqualTo' },
        onClick: (_ev, item?: IContextualMenuItem | undefined) => {
          setOperation(item?.key);
          datePicker.current?.showDatePickerPopup();
          setIsFilterSet(true);
        },
      },
      {
        key: 'Gt',
        text: 'Greater',
        iconProps: { iconName: 'ChevronRight' },
        onClick: (_ev, item?: IContextualMenuItem | undefined) => {
          setOperation(item?.key);
          datePicker.current?.showDatePickerPopup();
          setIsFilterSet(true);
        },
      },
      {
        key: 'Lt',
        text: 'Less than',
        iconProps: { iconName: 'ChevronLeft' },
        onClick: (_ev, item?: IContextualMenuItem | undefined) => {
          setOperation(item?.key);
          datePicker.current?.showDatePickerPopup();
          setIsFilterSet(true);
        },
      },
      {
        key: 'clear',
        text: 'Clear',
        iconProps: { iconName: 'ClearFilter' },
        onClick: () => {
          params.onFilterCleared();
          setIsFilterSet(false);
        }
      },
    ],
    directionalHintFixed: true,
  };

  return (
    <Stack horizontal verticalAlign="center">
      {params.columnName}
      <IconButton
        id="iconButton"
        iconProps={{ iconName: isFilterSet ? 'FilterSolid' : 'Filter' }}
        menuProps={menuProps}
        title="Filter" ariaLabel="Filter"
      />
      <DatePicker
        placeholder=""
        ariaLabel="Select a date"
        isMonthPickerVisible={false}
        calloutProps={{ target: '#iconButton' }}
        style={{ display: 'none' }}
        componentRef={datePicker}
        onSelectDate={(selectedDate: Date | null | undefined) => {
          const filterStr = encodeURIComponent(JSON.stringify(
            {
              f: [{ c: params.columnDbName, o: operation, v: selectedDate, t: params.type }]
            }));
          params.onFilterSelected(filterStr);
        }} />
    </Stack>)
}

export default HeaderWithFilter;
import { TextField, DialogType, Dialog, ITextField, Stack, IStackStyles, DefaultPalette, IStackItemStyles, IStackTokens, IconButton, ContextualMenu, CommandBar, mergeStyles, mergeStyleSets, SelectionMode, Toggle, FontIcon, Spinner, SpinnerSize } from '@fluentui/react'
import { useRef, useState } from 'react';
import PagedList, { IPagedListRefType } from '../common/PagedList';
import { useNavigate } from 'react-router-dom';
import { chat2Command, getChatQueries, IChat2CommandResponse, toggleChatQueries } from '../services/assetServices';
import { useSetAtom } from 'jotai';
import { chatCommandContentsAtom } from '../atoms/chatCommandAtoms';
import { RenderIf } from '../libs';
import { scrollStackItemStyles } from '../common/styles/StackStyles';

export interface IChatCommandDialogParams {
  toggleHideDialog: () => void;
  hideDialog: boolean | undefined;
}

export const createChatMessage = (response: IChat2CommandResponse): string => {
  if (response.error) {
    return `Some error happened: ${response.error}`;
  }
  return `Ok, ${response.action}ing ${response.entityType}. Please review and submit. ${(response.schemaValidationErrors?.length) ? `please note: ${response.schemaValidationErrors.join(',')}` : ''
    }`;
}

const ChatCommandDialog = (params: IChatCommandDialogParams) => {
  const dialogContentProps = {
    type: DialogType.close,
    title: 'Chat to Command (Powered by ChatGPT)',
  };

  const navigate = useNavigate();
  const setChatCommandContents = useSetAtom(chatCommandContentsAtom);
  const textRef = useRef<ITextField>(null);

  interface IChatMessage {
    source: 'chat' | 'user';
    message: string;
  }

  const defaultChatMessages: IChatMessage[] = [
    { source: 'chat', message: 'Hi, Enter your command as plain english' }
  ];
  const [chatMessages, setChatMessages] = useState<IChatMessage[]>(defaultChatMessages);
  const [isWaiting, setIsWaiting] = useState(false);

  const [lastMessage, setLastMessage] = useState("");

  const [showHistory, setShowHistory] = useState(false);

  const handleOkClick = () => {
    setChatMessages(prevVal => ([...prevVal
      , { message: textRef.current?.value ?? '', source: 'user' }
    ]));
    setLastMessage("");
    setIsWaiting(true);
    const abortController = new AbortController();
    chat2Command(abortController, { text: textRef.current?.value ?? '' })
      .then((data: IChat2CommandResponse) => {
        if (data.error) {
          alert(data.error);
        } else {
          setChatMessages(prevVal => ([...prevVal
            , { message: createChatMessage(data), source: 'chat' }
          ]));
          setChatCommandContents(data);
          if (data.entityType === "contractor" && data.action === 'add') {
            navigate('/newContractor')
          } else if (data.entityType === "contractor" && data.action === 'edit') {
            navigate('/editContractor?id=0')
          } else if (data.entityType === "asset" && data.action === 'add') {
            navigate('/newAsset');
          } else if (data.entityType === "asset" && data.action === 'edit') {
            navigate('/editAsset?id=0');
          } else if (data.entityType === "asset" && data.action === 'schedule') {
            params.toggleHideDialog();
            navigate('/assets?action=schedule');
          }
        }
      })
      .finally(() => setIsWaiting(false));
  }

  const stackStyles: IStackStyles = {
    root: {
      height: 250,
      overflow: 'scroll',
      borderColor: DefaultPalette.blue,
      borderStyle: 'solid',
      borderWidth: 1,
    },
  };
  const chatStackItemStyles: IStackItemStyles = {
    root: {
      background: DefaultPalette.themeSecondary,
      color: DefaultPalette.white,
      padding: 5,
      width: '80%',
    },
  };
  const userStackItemStyles: IStackItemStyles = {
    root: {
      background: DefaultPalette.themeLight,
      padding: 5,
      width: '80%',
    },
  };
  const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 5px',
  });
  const classNames = mergeStyleSets({
    gold: [{ color: 'gold' }, iconClass],
    gray: [{ color: 'gray' }, iconClass],
  });
  const [chatQueriesFav, setChatQueriesFav] = useState<Map<number, boolean>>();

  const [favChecked, setFavChecked] = useState(false);
  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <Dialog
      hidden={params.hideDialog}
      onDismiss={params.toggleHideDialog}
      dialogContentProps={dialogContentProps}
      maxWidth={450}
      modalProps={{ dragOptions: dragOptions }}
      styles={{ main: { height: 500, width: 300 } }}
    >
      <CommandBar
        items={[]}
        farItems={[
          {
            key: 'edit',
            text: 'Edit',
            iconOnly: true,
            iconProps: { iconName: 'PageHeaderEdit' },
            onClick: (ev) => setShowHistory(false),
            disabled: !showHistory,
          },
          {
            key: 'history',
            text: 'History',
            iconOnly: true,
            iconProps: { iconName: 'History' },
            onClick: (ev) => setShowHistory(true),
            disabled: showHistory,
          },
          {
            key: 'clear',
            text: 'Clear',
            iconOnly: true,
            iconProps: { iconName: 'Broom' },
            onClick: (ev) => setChatMessages(chatMessages.slice(0, 1)),
            disabled: showHistory,
          }
        ]}
      />

      <RenderIf condition={!showHistory} >
        <Stack tokens={itemAlignmentsStackTokens}>
          <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
            {chatMessages?.map(msg => msg.source === 'chat' ?
              <Stack.Item align="start" styles={chatStackItemStyles}>
                <span>{msg.message}</span>
              </Stack.Item>
              :
              <Stack.Item align="end" styles={userStackItemStyles}>
                <span>{msg.message}</span>
              </Stack.Item>
            )}
          </Stack>
          <Stack horizontal>
            <TextField width={450} componentRef={textRef} styles={{ root: { width: '100%' } }}
              value={lastMessage} multiline rows={3}
              onChange={(_, newValue) => setLastMessage(newValue ?? '')}
            />
            <RenderIf condition={isWaiting}>
              <Spinner size={SpinnerSize.small} />
            </RenderIf>
            <RenderIf condition={!isWaiting}>
              <IconButton iconProps={{ iconName: 'Send' }}
                onClick={handleOkClick} />
            </RenderIf>
          </Stack>
        </Stack>
      </RenderIf>
      <RenderIf condition={showHistory}>
        <Stack style={{ overflowX: 'scroll', height: 350 }}>
          <Stack.Item align="start" styles={scrollStackItemStyles} >
            <PagedList selectedTab='Chat'
              ref={pagedListRef}
              selectionMode={SelectionMode.none}
              columns={[
                {
                  key: '', name: '', minWidth: 20,
                  onRender: (item, index, column) => (
                    <IconButton styles={{ root: { height: 20 } }} iconProps={{
                      iconName: 'PlaybackRate1x',
                    }}
                      onClick={() => {
                        setShowHistory(false);
                        setLastMessage(item.request);
                      }} />
                  ),
                },
                { key: 'request', minWidth: 300, },
                {
                  key: 'favourite', name: '', minWidth: 20,
                  onRender: (item, index, column) => (
                    <IconButton
                      styles={{ root: { height: 20 } }}
                      iconProps={{
                        iconName: chatQueriesFav?.get(item.id) ? "FavoriteStarFill" : "FavoriteStar",
                        className: chatQueriesFav?.get(item.id) ? classNames.gold : classNames.gray
                      }}
                      onClick={() => {
                        const abortController = new AbortController();
                        toggleChatQueries(abortController, item.id);
                        const newChatQueriesFav = new Map<number, boolean>(chatQueriesFav);
                        newChatQueriesFav.set(item.id, !item.favourite);
                        setChatQueriesFav(newChatQueriesFav);
                      }}
                    />
                  )
                },
              ]}
              getAction={(abortController: AbortController, pageSize: number, pageNo: number, orderBy?: string | undefined, search?: string | undefined) => {
                const fetch = async (abortController: AbortController, pageSize: number, pageNo: number, orderBy?: string | undefined,
                  search?: string | undefined) => {
                  const fetchResult = await getChatQueries(abortController, pageSize, pageNo, orderBy, search);
                  fetchResult.items = fetchResult.items.filter(item => !favChecked || item.favourite)
                  setChatQueriesFav(new Map(fetchResult.items.map(cq => [cq.id, cq.favourite])));
                  return fetchResult;
                }

                const fetchResult = fetch(abortController, pageSize, pageNo, orderBy, search);
                return fetchResult;
              }}
              commandBarItems={[
                {
                  key: 'fav', onRender(item, dismissMenu) {
                    return (
                      <Toggle label='' defaultChecked={favChecked} inlineLabel onText="Favourites only" offText="All"
                        styles={{ root: { width: 185 } }}
                        onChange={(e, checked) => {
                          setFavChecked(checked ?? false);
                          pagedListRef.current?.refresh();
                        }} />
                    )
                  },
                }
              ]}
            ></PagedList>
          </Stack.Item>
        </Stack>
      </RenderIf>
    </Dialog >
  )
}

export default ChatCommandDialog
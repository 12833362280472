import { IStackStyles, Stack, Pivot, PivotItem } from '@fluentui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import Groups from './Groups';
import Users from './Users';
import Permissions from './Permissions';
import Tenants from './Tenants';
import PermissionsV2 from './PermissionsV2';

const PermissionsPivot = () => {
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");

  const navigate = useNavigate();
  const stackStyles: IStackStyles = {
    root: {
      label: {
        display: 'flex',
        paddingLeft: 10
      }
    }
  }

  return (
    <Stack styles={stackStyles} horizontalAlign="start">
      <Pivot defaultSelectedKey={tab ?? 'users'} onLinkClick={(item) => {
        if (item?.props.itemKey === 'back') {
          navigate('/');
        } else {
          navigate(`/security?tab=${item?.props.itemKey}`);
        }
      }}>
        <PivotItem itemIcon='ChromeBack' itemKey='back' />
        <PivotItem headerText="Users" itemKey='users'>
          <Users />
        </PivotItem>
        <PivotItem headerText="Groups" itemKey='groups'>
          <Groups />
        </PivotItem>
        {/* <PivotItem headerText="Permissions" itemKey='permissions'>
          <Permissions />
        </PivotItem> */}
        <PivotItem headerText="Permissions" itemKey='permissions'>
          <PermissionsV2 />
        </PivotItem>
        <PivotItem headerText="Tenants" itemKey='tenants'>
          <Tenants />
        </PivotItem>
      </Pivot>
    </Stack>)
}

export default PermissionsPivot;
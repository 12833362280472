import { DefaultButton, IconButton, IIconProps, ITextField, ITextFieldProps, Modal, PrimaryButton, Stack, TextField } from '@fluentui/react'
import ModalContentStyles from './styles/ModalContentStyles';
import ModalIconButtonStyles from './styles/ModalIconButtonStyles';
import { useRef } from 'react';

export interface IInputDialogParams {
  message: string;
  isModalOpen: boolean;
  hideModal: any;
  defaultValue?: string;
  onInputChange?: (newValue?: string) => void;
  onInputConfirmed?: (newValue?: string) => void;
}

const InputDialog = (params: IInputDialogParams) => {

  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const stackTokens = { childrenGap: 50 };
  const textFieldRef = useRef<ITextField>(null);

  return (
    <Modal
      isOpen={params.isModalOpen}
      onDismiss={params.hideModal}
      isBlocking={false}
    >
      {" "}
      <div className={ModalContentStyles.header}>
        <IconButton
          styles={ModalIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={params.hideModal}
        />
      </div>
      <Stack>
        <Stack.Item align="center">
          <p>{params.message}</p>
          <TextField
            componentRef={textFieldRef}
            defaultValue={params.defaultValue}
            onChange={(ev, newValue) => {
              if (params.onInputChange) {
                params.onInputChange(newValue);
              }
            }} />
          <p></p>
          <Stack horizontal tokens={stackTokens}>
            <PrimaryButton onClick={() => {
              params.hideModal();
              if (params.onInputConfirmed) {
                params.onInputConfirmed(textFieldRef.current?.value);
              }
            }}>
              Ok
            </PrimaryButton>
            <DefaultButton onClick={params.hideModal}>Cancel</DefaultButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  )
}

export default InputDialog
import { DefaultButton, IconButton, IIconProps, Modal, Text, Stack } from '@fluentui/react'
import { ReactElement } from 'react';
import ModalContentStyles from './styles/ModalContentStyles';
import ModalIconButtonStyles from './styles/ModalIconButtonStyles';

export interface IInfoDialogParams {
  message: string | ReactElement;
  isModalOpen: boolean;
  hideModal: any;
}

const InfoDialog = (params: IInfoDialogParams) => {

  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const stackTokens = { childrenGap: 50 };

  return (
    <Modal
      isOpen={params.isModalOpen}
      onDismiss={params.hideModal}
      isBlocking={false}
    >
      {" "}
      <div className={ModalContentStyles.header}>
        <Text variant="large">Information</Text>
        <IconButton
          styles={ModalIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={params.hideModal}
        />
      </div>
      <Stack>
        <Stack.Item align="center">
          {params.message}
          <Stack horizontal tokens={stackTokens} horizontalAlign="center">
            <DefaultButton onClick={params.hideModal}>OK</DefaultButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  )
}

export default InfoDialog
import { ITextField, Stack, CommandBar, IconButton, Label, Text, TextField, DetailsList, SelectionMode, Dropdown, IDropdown, IDropdownOption } from '@fluentui/react';
import { useRef, useState } from 'react'
import { IMetadata, IValidation } from '../../services/assetServices';

export interface IValidationEditorProps {
  selectedValidation?: IValidation;
  setSelectedValidation: (validation?: IValidation) => void;
  setSidePanel: (panel?: 'LookupEditor' | 'FieldEditor' | 'ValidationEditor' | undefined) => void;
  metadata?: IMetadata;
  setMetadata: (metadata?: IMetadata) => void;
  setMetadataStr: (metadataStr?: string) => void;
  validationAddOrEdit?: string;
  setValidationAddOrEdit: (validation: string) => void;
  editingValidationField?: boolean;
  setEditingValidationField: (editingValidationField: boolean) => void;
}

const validationRuleToDisplay = (rule: string, metadata?: IMetadata, fieldName?: string): string => {
  const field = (metadata && fieldName) ? metadata?.fields.find(f => f.name === fieldName) : undefined;
  switch (rule) {
    case 'NE':
      return 'Not Empty';
    default:
      if (rule.startsWith('NGT')) {
        return field?.fieldType === 'String' ? `Not longer than ${rule.replace('NGT', '')}` : `Not greater than ${rule.replace('NGT', '')}`
      } else if (rule.startsWith('NLT')) {
        return field?.fieldType === 'String' ? `Not shorter than ${rule.replace('NLT', '')}` : `Not less than ${rule.replace('NLT', '')}`
      }
      return rule;
  };
}
const toValidationRule = (ruleType: string, ruleValue: string): string => {
  return ruleType === 'NE' ? ruleType :
    ruleType === 'NG' ? `NGT${ruleValue}` :
      ruleType === 'NL' ? `NLT${ruleValue}` : '';
}

const getOptions = (metadata?: IMetadata, fieldName?: string): IDropdownOption<any>[] => {
  if (!fieldName) {
    return [];
  }
  const fieldType = (fieldName === 'code' || fieldName === 'name') ? 'String' :
    (metadata?.fields.find(f => f.name === fieldName)?.fieldType ?? '');
  if (fieldType === 'String') {
    return [
      { key: 'NE', text: 'Not empty' },
      { key: 'NG', text: 'Not longer than' },
      { key: 'NL', text: 'Not shorter than' },
    ]
  } else if (fieldType === 'Number') {
    return [
      { key: 'NE', text: 'Not empty' },
      { key: 'NG', text: 'Not greater than' },
      { key: 'NL', text: 'Not less than' },
    ]
  }
  return [];
}


const ValidationEditor = (props: IValidationEditorProps) => {
  const [index, setIndex] = useState<number | undefined>();
  const [validationRuleAddOrEdit, setValidationRuleAddOrEdit] = useState<string | undefined>();
  const [validationRule, setValidationRule] = useState<string>();
  const [validationRuleType, setValidationRuleType] = useState<string>();
  const [validationRuleValue, setValidationRuleValue] = useState<string>();
  const validationFieldTextFieldRef = useRef<ITextField>(null);
  const ruleTypeDropDownRef = useRef<IDropdown>(null);

  interface IValidationRuleWithIndex {
    index: number;
    rule: string;
  }

  const ValidationRuleEditor = () => {
    return (
      <Stack horizontal style={{ display: validationRuleAddOrEdit ? '' : 'none' }} >
        <Dropdown styles={{ root: { width: 145 } }} componentRef={ruleTypeDropDownRef}
          onChange={(ev, option) => {
            setValidationRuleType(option?.key.toString());
            setValidationRule(toValidationRule(option?.key.toString() ?? '', validationRuleValue ?? ''));
          }}
          options={getOptions(props.metadata, props.selectedValidation?.field)}
          defaultSelectedKey={validationRule?.substring(0, 2)}
        />
        <TextField value={validationRule?.substring(3)}
          styles={{ root: { width: 50, display: validationRuleType === 'NE' ? 'none' : '' } }}
          onChange={(ev, newValue) => {
            setValidationRuleValue(newValue);
            setValidationRule(toValidationRule(validationRuleType ?? '', newValue ?? ''));
          }} />
        <Stack.Item grow>
          <span />
        </Stack.Item>
        <IconButton iconProps={{ iconName: 'CheckMark' }} onClick={() => {
          const newValidation = { field: props.selectedValidation?.field ?? '', rules: props.selectedValidation?.rules ?? [] };

          if (newValidation && newValidation.rules && validationRule) {
            const validations = props.metadata?.validations.filter(v => v.field !== props.selectedValidation?.field) ?? [];
            if (validationRuleAddOrEdit === 'add') {
              newValidation?.rules?.push(validationRule);
            } else if (validationRuleAddOrEdit === 'edit' && (index !== undefined)) {
              newValidation.rules[index] = validationRule
            }
            if (newValidation.field && newValidation.rules) {
              validations?.push(newValidation);
              props.setSelectedValidation(newValidation);
              const newMetadata = {
                ...props.metadata,
                lookups: [...props.metadata?.lookups ?? []], fields: [...props.metadata?.fields ?? []], validations: validations
              };
              props.setMetadata(newMetadata);
              props.setMetadataStr(JSON.stringify(newMetadata, null, 2));
            }
          }
          setValidationRule("");
          setValidationRuleAddOrEdit(undefined);
        }} />
        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => { setValidationRuleAddOrEdit(undefined) }} />
      </Stack>
    )
  }
  return (
    <Stack>
      <CommandBar style={{ width: '100%' }}
        items={[
          {
            key: 'ValidationRule',
            onRender: () => (
              <Text variant='large'>{props.selectedValidation?.field}</Text>
            )
          },
          {
            key: 'AddValidation',
            onRender: () => (
              <IconButton iconProps={{ iconName: 'Add' }} onClick={() => setValidationRuleAddOrEdit('add')} />
            )
          }
        ]}
      />
      <Stack horizontal>
        <Label style={{ width: 75 }}>field</Label>
        <TextField defaultValue={props.selectedValidation?.field} readOnly={!props.editingValidationField} borderless={!props.editingValidationField}
          componentRef={validationFieldTextFieldRef} />
        <IconButton iconProps={{ iconName: "Rename" }} style={{ display: props.editingValidationField ? 'none' : '' }}
          onClick={() => props.setEditingValidationField(!props.editingValidationField)} />
        <IconButton iconProps={{ iconName: 'CheckMark' }} style={{ display: props.editingValidationField ? '' : 'none' }} onClick={() => {
          const newMetadata = { ...props.metadata, lookups: props.metadata?.lookups ?? [], fields: props.metadata?.fields ?? [], validations: props.metadata?.validations ?? [] };
          if (props.selectedValidation) {
            const editedValidation = { ...props.selectedValidation };
            editedValidation.field = validationFieldTextFieldRef.current?.value ?? '';
            props.setSelectedValidation(editedValidation);
            const validationIndex = props.metadata?.validations?.findIndex(v => v.field === props?.selectedValidation?.field);
            if (validationIndex !== undefined) {
              newMetadata.validations[validationIndex] = editedValidation;
              props.setMetadata(newMetadata);
              props.setMetadataStr(JSON.stringify(newMetadata, null, 2));
            }
          } else if (props.validationAddOrEdit === 'add') {
            const newValidation = { field: validationFieldTextFieldRef.current?.value ?? '', rules: [] };
            newMetadata.validations.push(newValidation);
            props.setMetadata(newMetadata);
            props.setMetadataStr(JSON.stringify(newMetadata, null, 2));
            props.setSelectedValidation(newValidation);
            props.setValidationAddOrEdit('edit');
          }
          props.setEditingValidationField(false);
        }} />
        <IconButton iconProps={{ iconName: 'Cancel' }} style={{ display: props.editingValidationField ? '' : 'none' }} onClick={() => {
          props.setEditingValidationField(!props.editingValidationField)
        }} />
      </Stack>
      <DetailsList
        items={props.selectedValidation?.rules?.map((r, index) => ({ rule: r, index: index })) ?? []}
        selectionMode={SelectionMode.none}
        columns={[
          {
            key: 'rule',
            name: 'rule',
            fieldName: 'rule',
            minWidth: 150,
            onRender: (item: IValidationRuleWithIndex) => (
              <span>{validationRuleToDisplay(item.rule, props.metadata, props?.selectedValidation?.field)}</span>
            )
          },
          {
            key: 'action',
            name: 'action',
            minWidth: 50,
            onRender: (item: IValidationRuleWithIndex) => (
              <Stack horizontal>
                <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                  setValidationRuleAddOrEdit('edit');
                  setValidationRule(item.rule);
                  setIndex(item.index);
                }} />
                <IconButton iconProps={{ iconName: "Delete" }} onClick={() => {
                  if (props.selectedValidation) {
                    const newValidation = { ...props.selectedValidation };
                    newValidation.rules = props.selectedValidation?.rules.filter(r => r !== item.rule);
                    props.setSelectedValidation(newValidation);
                    const newMetadata = { ...props.metadata, lookups: props.metadata?.lookups ?? [], fields: props.metadata?.fields ?? [], validations: props.metadata?.validations ?? [] };
                    const validationIndex = props.metadata?.validations?.findIndex(v => v.field === props?.selectedValidation?.field);
                    if (validationIndex !== undefined) {
                      newMetadata.validations[validationIndex] = newValidation;
                      props.setMetadata(newMetadata);
                      props.setMetadataStr(JSON.stringify(newMetadata, null, 2));
                    }
                  }
                }} />
              </Stack>
            )
          },
        ]}
      />
      <ValidationRuleEditor />
    </Stack>
  )
}

export default ValidationEditor
import { IProfileData, profileDataAtom } from '../atoms/authAtoms';
import { useAtom } from 'jotai';
import { DetailsList, Link, Panel, PanelType, SelectionMode, Text } from '@fluentui/react'
import { ITenant, changeCurrentTenant } from '../services/assetServices';

export interface ITenantSelectorProps {
  isOpen: boolean;
  hideTenantSelector: any;
}

const TenantSelector = (props: ITenantSelectorProps) => {
  const [profileData, setProfileData] = useAtom(profileDataAtom);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideTenantSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText={profileData.userPrincipalName}
    >
      <DetailsList
        items={profileData?.tenants}
        columns={[{
          key: 'name',
          name: 'Tenant name',
          fieldName: 'name',
          minWidth: 100,
          onRender: (item: ITenant, index, column) => (
            <Link disabled={item.id === profileData.currentTenant}
              onClick={() => {
                const abortController = new AbortController();
                changeCurrentTenant(abortController, profileData.userPrincipalName, item.id)
                  .then(() => {
                    props.hideTenantSelector();
                    setProfileData((p: IProfileData) => (
                      { ...p, currentTenant: item.id }
                    ));
                  })
              }
              }>{item.name} {item.id === profileData.currentTenant ? '(Currently selected)' : ''}</Link>
          )
        }]}
        selectionMode={SelectionMode.none} />
    </Panel>
  )
}

export default TenantSelector
import { IProfileData } from "./atoms/authAtoms";

export const ActionsNames: any = {
  100: "Manage Assets",
  110: "Manage Issues",
  120: "Manage WorkOrders",
  130: "Manage Metadata",
  140: "Manage Zones",
  150: "Manage Contractors",
  160: "Reports",
  170: "Analytics",
  180: "Import",
  190: "Chat to command",
  200: "Manage Assets Categories",
  210: "Permissions",
  220: "Manage Settings",
}

export const Actions: any = {
  ManageAssets: 100,
  ManageIssues: 110,
  ManageWorkOrders: 120,
  ManageMetadata: 130,
  ManageZones: 140,
  ManageContractors: 150,
  Reports: 160,
  Analytics: 170,
  Import: 180,
  ChatToCommand: 190,
  ManageAssetsCategories: 200,
  Permissions: 210,
  ManageSettings: 220,
}


export const canDo = (profile: IProfileData, action: number): boolean => {
  return !!profile?.permissions?.find(p => p === action);
}
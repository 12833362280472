import { atom } from "jotai";
import { ITenant } from "../services/assetServices";

export const tenantAtom = atom<ITenant | undefined>(undefined);
  
export const tenantLogoAtom = atom<string>(
    (get) => {
        const otherInfo = get(tenantAtom)?.otherInfo;
        return otherInfo ? JSON.parse(otherInfo).logo : undefined; 
    }
)



import {
  Dropdown,
  IDropdownOption,
  IStackItemStyles,
  IStackTokens,
  Stack,
  Text,
} from "@fluentui/react";
import { useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  errorMessageAtom,
  isInProgressAtom,
} from "../../atoms/messageBarAtoms";
import { IChartData } from "../../services/assetServices";

interface PanelProps {
  label: string;
  fetchOptionsFunction: () => Promise<IDropdownOption<any>[]>;
  fetchDataFunction: (status: string) => Promise<IChartData>;
}

const CountCardPanel: React.FC<PanelProps> = ({
  label,
  fetchOptionsFunction,
  fetchDataFunction,
}) => {
  const [status, setStatus] = useState<string>("0");
  const [options, setOptions] = useState<IDropdownOption<any>[]>();
  const [count, setCount] = useState<number | undefined>(0);
  const [loading, setLoading] = useState(true);

  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const fetchData = async () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    try {
      setLoading(true);
      const options = await fetchOptionsFunction();
      options.unshift({ key: "0", text: "All" });
      setOptions(options);
      const data: IChartData = await fetchDataFunction(status ?? "0");
      const count = Object.values(data.values)[0];
      setCount(count);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
      setLoading(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, [status, fetchDataFunction, fetchOptionsFunction]);

  const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };

  const stackItemStyles: IStackItemStyles = {
    root: {
      display: "flex",
      justifyContent: "right",
    },
  };

  return loading ? (
    <span>Loading...</span>
  ) : (
    <>
      <Stack tokens={itemAlignmentsStackTokens} horizontal>
        <Stack.Item>
          <Text variant="xLarge">{label}</Text>
        </Stack.Item>
        <Stack.Item grow styles={stackItemStyles}>
          <Dropdown
            style={{ width: 80 }}
            options={options ?? []}
            selectedKey={status}
            onChange={(_, option) => {
              setStatus(option?.key?.toString() ?? "");
            }}
            calloutProps={{ calloutWidth: undefined }}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal verticalAlign="center" horizontalAlign="center">
        <Text variant="mega">{count?.toString()}</Text>
      </Stack>
    </>
  );
};

export default CountCardPanel;

import { IDetailsListStyles, mergeStyleSets } from "@fluentui/react";

export const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '375px',
                width: '850px',
            },
        },
    },
    headerWrapper: {
        flex: '0 0 auto',
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflow: 'hidden',
    },
};

export const gridStylesNames = mergeStyleSets({
    header: {
        margin: 0,
    },
    row: {
        flex: '0 0 auto',
    },
    focusZone: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    selectionZone: {
        height: '100%',
        overflow: 'hidden',
    },
});

export const focusZoneProps = {
    className: gridStylesNames.focusZone,
    'data-is-scrollable': 'true',
} as React.HTMLAttributes<HTMLElement>;
import React, { useContext, useEffect, useRef, useState } from "react";
import GridLayout, { ItemCallback, Layout } from "react-grid-layout";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import AssetsPanel, { IAssetsPanelRef } from "./AssetsPanel";
import AppContext from "../AppContext";
import { profileDataAtom } from "../../atoms/authAtoms";
import {
  errorMessageAtom,
  isInProgressAtom,
} from "../../atoms/messageBarAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import { CommandBar, ICommandBarItemProps, mergeStyles } from "@fluentui/react";
import IssuesCountCardPanel from "./IssuesCountCardPanel";
import WorkOrdersCountCardPanel from "./WorkOrdersCountCardPanel";

const Dashboard = () => {
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const profileData = useAtomValue(profileDataAtom);

  const [editMode, setEditMode] = useState(false);

  const fetchData = async () => {
    context.setSelectedTab("Dashboard");
    setIsInProgress(true);
    const abortController = new AbortController();

    try {
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  const originalLayout: Layout[] = [
    { i: "AssetsPanel", x: 0, y: 0, w: 4, h: 8, static: !editMode },
    {
      i: "IssuesCountCardPanel",
      x: 0,
      y: 8,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 3,
      minH: 2,
      maxH: 4,
      static: !editMode,
    },
    {
      i: "WorkOrdersCountCardPanel",
      x: 2,
      y: 8,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 3,
      minH: 2,
      maxH: 4,
      static: !editMode,
    },
  ];

  useEffect(() => {
    const loadedLayout: Layout[] = getFromLS("layout");
    if (loadedLayout) {
      loadedLayout.forEach((l) => (l.static = true));
    }
    setLayout(loadedLayout ?? originalLayout);
    fetchData();
  }, []);

  const [layout, setLayout] = useState<Layout[]>(originalLayout);

  const _items: ICommandBarItemProps[] = [
    {
      key: "editDashboard",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disabled: !!editMode,
      onClick: () => {
        setEditMode(true);
        setLayout(layout.map((l) => ({ ...l, static: false })));
      },
    },
    {
      key: "saveDashboard",
      text: "Save",
      iconProps: { iconName: "Save" },
      disabled: !editMode,
      onClick: () => {
        setEditMode(false);
        saveToLS("layout", layout);
        setLayout(layout.map((l) => ({ ...l, static: true })));
      },
    },
    {
      key: "cancelEdit",
      text: "Cancel",
      iconProps: { iconName: "Cancel" },
      disabled: !editMode,
      onClick: () => {
        setEditMode(false);
        setLayout(layout.map((l) => ({ ...l, static: true })));
      },
    },
  ];

  const panelStyle = mergeStyles({ border: "outset" });

  let gridWidth = 1200;
  const gridCols = 12;
  const rowHeight = 30;

  const assetPanelRef = useRef<IAssetsPanelRef>(null);

  function getFromLS(key: string): any {
    let ls = undefined;
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem(key) ?? "") || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls;
  }

  function saveToLS(key: string, value: any) {
    if (global.localStorage) {
      global.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  return (
    <>
      <CommandBar
        items={[]}
        farItems={_items}
        ariaLabel="Items actions"
        primaryGroupAriaLabel="Items actions"
        farItemsGroupAriaLabel="More actions"
      />
      <GridLayout
        className="layout"
        layout={layout}
        cols={gridCols}
        rowHeight={rowHeight}
        width={gridWidth}
        onLayoutChange={(layouts: Layout[]) => setLayout(layouts)}
        onResizeStop={(layouts: Layout[]) => {
          layouts.forEach((l: Layout) => {
            const newSize = {
              width: (l.w * gridWidth) / gridCols,
              height: l.h * rowHeight,
            };
            switch (l.i) {
              case "AssetsPanel":
                console.log(newSize);
                if (assetPanelRef.current) {
                  assetPanelRef.current.Resize(newSize.width, newSize.height);
                }
                break;
              default:
                break;
            }
          });
        }}
      >
        <div key="AssetsPanel" className={panelStyle}>
          <AssetsPanel ref={assetPanelRef} />
        </div>
        <div key="IssuesCountCardPanel" className={panelStyle}>
          <IssuesCountCardPanel />
        </div>
        <div key="WorkOrdersCountCardPanel" className={panelStyle}>
          <WorkOrdersCountCardPanel />
        </div>
      </GridLayout>
    </>
  );
};

export default Dashboard;

import { useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import {
  isInProgressAtom,
  errorMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  ICreateWorkOrderCommentRequest,
  ICreateWorkOrderCommentResponse,
  IUpdateWorkOrderCommentRequest,
  IUpdateWorkOrderCommentResponse,
  IWorkOrderComment,
  createWorkOrderComment,
  getWorkOrderComment,
  getWorkOrderComments,
  updateWorkOrderComment,
} from "../../services/assetServices";
import {
  CommandBar,
  DetailsRow,
  GroupedList,
  IGroup,
  IGroupHeaderProps,
  ITextField,
  IconButton,
  SelectionMode,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { formatDateToNow } from "../../common/DateHelper";

export interface IWorkOrderCommentsParams {
  workOrderId: number | undefined;
}
const WorkOrderComments = (params: IWorkOrderCommentsParams) => {
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);

  const [comments, setComments] = useState<IWorkOrderComment[]>([]);
  const [editMode, setEditMode] = useState<number | null>(null);
  const [createMode, setCreateMode] = useState<boolean>();
  const [newComment, setNewComment] = useState<string>();

  const editTextField = useRef<ITextField>(null);

  const updateCommentWithCompleteContent = async (commentId: number) => {
    const indexToUpdate = comments.findIndex(
      (comment) => comment.id === commentId
    );

    if (indexToUpdate !== -1) {
      const updatedComments = [...comments]; // Create a new array to avoid mutating the state directly

      const abortController = new AbortController();
      const woComment = await getWorkOrderComment(abortController, commentId);
      // Fetch the complete content
      const completeContent = woComment.content;

      // Update the comment with complete content
      updatedComments[indexToUpdate] = {
        ...updatedComments[indexToUpdate],
        content: completeContent,
      };

      // Update the state
      setComments(updatedComments);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        if (!params.workOrderId) {
          throw new Error("workOrderId not provided");
        }
        const woComments = await getWorkOrderComments(
          abortController,
          params.workOrderId
        );
        setComments(
          woComments.items.map((c) => ({
            ...c,
            createdAtFormatted: formatDateToNow(c.createdAt),
            modifiedByUserName:
              c.modifiedAt === c.createdAt ? undefined : c.modifiedByUserName,
            modifiedAtFormatted:
              c.modifiedAt === c.createdAt
                ? undefined : formatDateToNow(c.modifiedAt),
          }))
        );
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
    if (props) {
      const toggleCollapse = (): void => {
        const { id } = props.group!.data;
        updateCommentWithCompleteContent(id);
        setEditMode(editMode === id ? null : id);
        props.onToggleCollapse!(props.group!);
      };
      return (
        <div>
          <DetailsRow
            columns={[
              {
                key: "id",
                name: "Id",
                fieldName: "id",
                minWidth: 100,
              },
              {
                key: "createdAt",
                name: "CreatedAt",
                fieldName: "createdAtFormatted",
                minWidth: 100,
              },
              {
                key: "createdByUserName",
                name: "Created By",
                fieldName: "createByUserName",
                minWidth: 100,
              },
              {
                key: "modifiedByUserName",
                name: "Modified By",
                fieldName: "modifiedByUserName",
                minWidth: 100,
              },
              {
                key: "modifiedAt",
                name: "Modified By",
                fieldName: "modifiedAtFormatted",
                minWidth: 100,
              },
            ]}
            item={props.group?.data}
            itemIndex={1}
            styles={{ cell: { width: 14 } }}
            compact={true}
          />
          {editMode === props.group?.data.id ? (
            <TextField
              defaultValue={props.group?.data.content}
              multiline
              rows={3}
              componentRef={editTextField}
            />
          ) : (
            <>
              <Text>{props.group?.data.content}</Text>
              <br />
            </>
          )}
          {editMode === props.group?.data.id ? (
            <>
              <IconButton
                iconProps={{ iconName: "Accept" }}
                onClick={() => {
                  handleUpdateNewComment(
                    props.group?.data.id,
                    editTextField.current?.value ?? ""
                  );
                  toggleCollapse();
                }}
              />
              <IconButton
                iconProps={{ iconName: "Cancel" }}
                onClick={() => {
                  toggleCollapse();
                }}
              />
            </>
          ) : (
            <IconButton
              iconProps={{ iconName: "Edit" }}
              onClick={() => {
                toggleCollapse();
              }}
            />
          )}
        </div>
      );
    }

    return null;
  };

  const groupedListProps = {
    onRenderHeader,
    // onRenderFooter,
  };

  const onRenderCell = (
    nestingDepth?: number,
    item?: IWorkOrderComment,
    itemIndex?: number,
    group?: IGroup
  ): React.ReactNode => {
    return item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <TextField value={item.content} multiline rows={3} />
    ) : null;
  };

  const handleCreateNewComment = async (
    workOrderId: number,
    newComment: string
  ) => {
    const abortController = new AbortController();
    const newCommentRequest: ICreateWorkOrderCommentRequest = {
      workOrderId: workOrderId,
      content: newComment,
    };
    const response: ICreateWorkOrderCommentResponse =
      await createWorkOrderComment(abortController, newCommentRequest);
    comments.unshift({
      id: response.id,
      content: newComment,
      createdAtFormatted: formatDateToNow(response.createdAt),
      createByUserName: response.createByUserName,
    });
    setComments(comments);
    setCreateMode(false);
  };

  const handleUpdateNewComment = async (
    commentId: number,
    newComment: string
  ) => {
    const abortController = new AbortController();
    const updateCommentRequest: IUpdateWorkOrderCommentRequest = {
      commentId: commentId,
      content: newComment,
    };
    const response: IUpdateWorkOrderCommentResponse =
      await updateWorkOrderComment(abortController, updateCommentRequest);
    const index = comments.findIndex((c) => c.id === commentId);
    comments[index].content = newComment;
    comments[index].modifiedAtFormatted = formatDateToNow(response.modifiedAt);
    comments[index].modifiedByUserName = response.modifiedByUserName;
    setComments(comments);
    setCreateMode(false);
  };

  return (
    <>
      <CommandBar
        items={[
          {
            key: "addComment",
            text: "Add",
            iconProps: { iconName: "Add" },
            onClick: () => {
              setCreateMode(true);
            },
          },
        ]}
        ariaLabel="Items actions"
      />
      {createMode ? (
        <Stack>
          <TextField
            value={newComment}
            onChange={(_, newValue) => setNewComment(newValue)}
          />
          <Stack horizontal>
            <IconButton
              iconProps={{ iconName: "Accept" }}
              onClick={() => {
                if (params.workOrderId && newComment) {
                  handleCreateNewComment(params.workOrderId, newComment);
                }
              }}
            />
            <IconButton
              iconProps={{ iconName: "Cancel" }}
              onClick={() => {
                setCreateMode(false);
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
      <GroupedList
        items={comments}
        onRenderCell={onRenderCell}
        selectionMode={SelectionMode.none}
        groupProps={groupedListProps}
        groups={comments.map((c, index) => ({
          count: 1,
          key: c.id.toString(),
          name: c.content,
          data: c,
          startIndex: index,
          isCollapsed: true,
        }))}
        compact={true}
      />
    </>
  );
};

export default WorkOrderComments;

import { Scheduler } from "@aldabil/react-scheduler";
import { ProcessedEvent } from "@aldabil/react-scheduler/types";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { Dropdown, IDropdownOption, IconButton, Stack } from "@fluentui/react";
import {
  IField,
  getAssets,
  getMetadata,
  getWorkOrders,
} from "../../services/assetServices";

const Calendar = () => {
  const context = useContext(AppContext);

  const [entity, setEntity] = useState("Asset");
  const [fields, setFields] = useState<IField[]>([]);
  const [selectedFields, setSelectedFields] = useState<IField[]>([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [events, setEvents] = useState<ProcessedEvent[]>([]);
  const colors = [
    "ForestGreen",
    "DarkOrange",
    "CadetBlue",
    "Coral",
    "CornflowerBlue",
  ];
  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      const metadata = await getMetadata(abortController, entity);
      const metadataFields = metadata.fields;
      const dateFields = metadataFields.filter(
        (field: IField) => field.fieldType === "Date"
      );
      setFields(dateFields);
      const fieldNames = metadataFields
        .filter((field: IField) => field.showInGrid)
        .map((field: IField) => field.name);
      if (selectedFields) {
        const data =
          entity === "Asset"
            ? await getAssets(abortController, 100, 1)
            : entity === "WorkOrder"
            ? await getWorkOrders(abortController, 100, 1)
            : null;
        if (data === null) {
          return;
        }
        const items = data?.items.map((item: any) => {
          const newItem: any = { ...item };
          const otherInfoParts = item.otherInfo?.split("|");
          if (otherInfoParts) {
            fieldNames?.forEach((mf, index) => {
              const part = otherInfoParts[index];
              newItem[mf] = part;
            });
          }
          return newItem;
        });
        const events: ProcessedEvent[] = [];
        let eventId: number = 0;
        items.forEach((item: any) => {
          selectedFields.forEach((dateField: IField, index: number) => {
            if (item[dateField.name]) {
              events.push({
                event_id: ++eventId,
                title: entity === "Asset" ? item.name : item.code,
                start: new Date(new Date(item[dateField.name]).setHours(9)),
                end: new Date(new Date(item[dateField.name]).setHours(10)),
                color: colors[index],
              });
            }
          });
        });
        setEvents(events);
      }
    };
    context.setSelectedTab("Calendar");
    fetchData();
    return () => {};
  }, [refreshCount, selectedFields, entity]);

  const handleEntityDownChange = (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any>
  ) => {
    if (!option) {
      return;
    }
    setEntity(option.key as string);
    setSelectedFields([]);
    setFields([]);
  };

  const handleFieldDropDownChange = (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any>
  ) => {
    if (!option) {
      return;
    }
    if (option.selected) {
      const newSelectedField: IField | undefined = fields.find(
        (f) => f.name === option.key
      );
      if (newSelectedField) {
        const newSelectedFields: IField[] = [
          ...selectedFields,
          newSelectedField,
        ];
        setSelectedFields(newSelectedFields);
      }
    } else {
      setSelectedFields(
        selectedFields.filter((field: IField) => field.name !== option?.key)
      );
    }
  };

  const CalendarLegend = () => {
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 15 }}
        styles={{ root: { width: 200 } }}
      >
        {selectedFields.map((field: IField, index: number) => (
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <div
              style={{ backgroundColor: colors[index], width: 25, height: 25 }}
            />
            <div>{field.name}</div>
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Dropdown
          styles={{ root: { width: 175 } }}
          options={[
            { key: "Asset", text: "Asset" },
            { key: "WorkOrder", text: "WorkOrder" },
            { key: "Contractor", text: "Contractor" },
          ]}
          selectedKey={entity}
          onChange={handleEntityDownChange}
        />
        <Dropdown
          multiSelect
          styles={{ root: { width: 250 } }}
          options={fields.map((field) => ({
            key: field.name,
            text: field.label,
          }))}
          selectedKeys={selectedFields.map((field) => field.name)}
          onChange={handleFieldDropDownChange}
        />
        <IconButton
          iconProps={{ iconName: "Refresh" }}
          onClick={() => setRefreshCount(refreshCount + 1)}
        />
        <CalendarLegend />
      </Stack>
      <Scheduler events={events} view="month" />
    </Stack>
  );
};

export default Calendar;

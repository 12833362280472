import { useEffect, useRef, useState } from "react";
import { appRoles } from "../../authConfig";
import PagedList, { IPagedListRefType } from "../../common/PagedList";
import {
    deleteRefValue,
  getReferenceValues,
  getReferences,
} from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "@fluentui/react";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";

const References = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const [selectedRefId, setSelectedRefId] = useState<string>();
  const pagedListRef = useRef<IPagedListRefType>(null);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [references, setReferences] = useState<any[]>();
  const [refName, setRefName] = useState<string>();
  
  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        const references: string[] = await getReferences(abortController);
        setReferences(references);
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  return (
    <>
      <div>References</div>
      <Dropdown
        styles={{ root: { width: 250 } }}
        options={
          references?.map((ref: string) => ({ key: ref, text: ref })) ?? []
        }
        onChange={(ev, option) => {
            setRefName(option?.key.toString());
            pagedListRef.current?.refresh();
        }}
      />
      <PagedList
        selectedTab="References"
        ref={pagedListRef}
        columns={[
          { key: "id", minWidth: 45, maxWidth: 50 },
          { key: "key", minWidth: 75, maxWidth: 100 },
          { key: "value", minWidth: 200, maxWidth: 250 },
          { key: "parentRef", minWidth: 100, maxWidth: 150 },
          { key: "parentKey", minWidth: 75, maxWidth: 100 },
        ]}
        getAction={(
          abortController: AbortController,
          pageSize: number,
          pageNo: number,
          orderBy?: string | undefined,
          search?: string | undefined
        ) => {
          return getReferenceValues(
            abortController,
            pageSize,
            pageNo,
            refName ?? '',
            orderBy,
            search
          );
        }}
        commandBarItems={[
          {
            key: "newRefValue",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => navigate(`/newRefValue?refName=${refName}`),
            disabled: !profileData.roles.includes(appRoles.Admin),
          },
          {
            key: "editRefValue",
            text: "Edit",
            iconProps: { iconName: "Edit" },
            onClick: () => navigate(`/editRefValue?id=${selectedRefId}`),
            disabled:
              !profileData.roles.includes(appRoles.Admin) || !selectedRefId,
          },
          {
            key: "deleteRefValue",
            text: "Delete",
            iconProps: { iconName: "Delete" },
            onClick: () => {
              if (selectedRefId) {
                const abortController = new AbortController();
                deleteRefValue(abortController, Number(selectedRefId)).then(
                  () => {
                    pagedListRef.current?.refresh();
                    setSuccessMessage(
                      `Reference Value with id: ${selectedRefId} deleted.`
                    );
                  }
                );
              }
            },
            disabled:
              !profileData.roles.includes(appRoles.Admin) || !selectedRefId,
          },
        ]}
        onSelectionChange={(selection: any) => {
          setSelectedRefId(selection.id);
        }}
      ></PagedList>
    </>
  );
};

export default References;

import { useContext } from 'react'
import { VerticalBarChart } from '@fluentui/react-charting';
import AppContext from '../AppContext';

const MiniChart = () => {
  const context = useContext(AppContext);
  const xAxisFormat = context.miniChartOptions.xAxisFormat;
  // console.log(JSON.stringify(context.miniChartData));
  const map = new Map(Object.entries(context.miniChartData));

  const data = Array.from(map, ([name, value]) => ({
    x: xAxisFormat ? xAxisFormat(name) : name,
    y: value
  }));

  return (
    <>
      <div style={{ width: 200, height: 50 }}>
        {data.length > 0 ?
          <VerticalBarChart
            culture={window.navigator.language}
            chartTitle="Vertical bar chart basic example "
            data={data}
            useSingleColor={false}
            hideLabels={true}
            hideLegend={true}
            xAxisPadding={0}
            yAxisTickCount={1}
            margins={{ top: 10, bottom: 15, left: 40, right: 20 }}
          /> : <></>}
      </div>
    </>
  )
}

export default MiniChart
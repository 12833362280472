import {
  ChoiceGroup,
  DetailsList,
  IChoiceGroupOption,
  Link,
  Panel,
  PanelType,
  SearchBox,
  SelectionMode,
} from "@fluentui/react";
import { useState } from "react";
import {
  getUsers,
  IPagedCollection,
  IUser,
  IGroup,
  getGroups,
} from "../../services/assetServices";

export interface IUserOrGroupSelectorProps {
  isOpen: boolean;
  hideUsersAndGroupsSelector: any;
  onUserOrGroupSelected: (
    id: number,
    isGroup: boolean,
    username?: string,
    name?: string
  ) => void;
  userOrGroupOnly?: "user" | "group";
}

const UserOrGroupSelector = (props: IUserOrGroupSelectorProps) => {
  const [usersOrGroups, setUsersOrGroups] = useState<IUser[]>([]);
  const [userOrGroup, setUserOrGroup] = useState<string | undefined>(
    props.userOrGroupOnly === "group"
      ? "group"
      : props.userOrGroupOnly === "user"
      ? "user"
      : undefined
  );

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideUsersAndGroupsSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText={
        props.userOrGroupOnly === "user"
          ? "Pick a user"
          : props.userOrGroupOnly === "group"
          ? "Pick a group"
          : "Pick user or group to set permission"
      }
    >
      {props.userOrGroupOnly ? (
        <></>
      ) : (
        <ChoiceGroup
          defaultSelectedKey="B"
          options={[
            { key: "user", text: "User" },
            { key: "group", text: "Group" },
          ]}
          onChange={(ev, option: IChoiceGroupOption | undefined) =>
            setUserOrGroup(option?.key ?? "user")
          }
          label="Pick one"
          required={true}
        />
      )}
      <SearchBox
        placeholder={`Search ${userOrGroup === "user" ? "users" : "groups"}`}
        onSearch={(newValue) => {
          const abortController = new AbortController();
          if (userOrGroup === "user") {
            getUsers(abortController, 10, 1, undefined, newValue).then(
              (users: IPagedCollection<IUser>) => {
                setUsersOrGroups(users.items);
              }
            );
          } else {
            getGroups(abortController, 10, 1, undefined, newValue).then(
              (groups: IPagedCollection<IGroup>) => {
                setUsersOrGroups(
                  groups.items?.map((group) => ({
                    id: group.id,
                    username: group.name,
                    email: "",
                  }))
                );
              }
            );
          }
        }}
        onClear={() => setUsersOrGroups([])}
      />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item) => (
              <Link
                key={item.id}
                onClick={() => {
                  props.onUserOrGroupSelected(
                    item.id,
                    userOrGroup === "group",
                    item.username,
                    item.name
                  );
                  props.hideUsersAndGroupsSelector();
                }}
              >
                {item.id}
              </Link>
            ),
          },
          {
            key: "Username",
            name:
              props.userOrGroupOnly === "user"
                ? "User name"
                : props.userOrGroupOnly === "group"
                ? "Group name"
                : "Group/User name",
            fieldName: "username",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          ...(props.userOrGroupOnly === "group"
            ? []
            : [
                {
                  key: "Email",
                  name: "Email",
                  fieldName: "email",
                  minWidth: 250,
                  maxWidth: 250,
                  isResizable: true,
                },
              ]),
        ]}
        compact={true}
        items={usersOrGroups}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  );
};

export default UserOrGroupSelector;

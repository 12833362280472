import { Stack, IconButton, TextField, Spinner } from "@fluentui/react";
import { useState, useRef } from "react";
import { uploadImage, IUploadFileResponse } from "../services/assetServices";
import { RenderIf } from "../libs";

export interface IFileSelectorProps {
  value: string;
  imageSource?: string;
  uploadImage: boolean;
  purpose: string;
  onUpload?: (id: number, url: string, name: string) => void;
  isTextFile?: boolean;
  onFileSelected?: (name: string, content: string | ArrayBuffer) => void;
}

export const FileSelector = (params: IFileSelectorProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isSelected, setIsSelected] = useState(false);

  const fileUploadRef = useRef(null);

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const [uploading, setUploading] = useState(false);

  const handleUploadClick = () => {
    if (!selectedFile?.name) {
      return;
    }
    const blob: Blob = selectedFile as Blob;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const result = reader.result;
      const abortController = new AbortController();
      const content = params.isTextFile ? result as string : result as ArrayBuffer;
      if (params.onFileSelected) {
        params.onFileSelected(selectedFile?.name, content);
      }
      if (params.uploadImage) {
        setUploading(true);
        uploadImage(abortController, selectedFile?.name, params.purpose, content as ArrayBuffer)
          .then((data: IUploadFileResponse) => {
            if (params.onUpload) {
              params.onUpload(data.id, data.url, selectedFile?.name);
            }
          })
          .finally(() => setUploading(false));
      }
    })
    if (params.isTextFile) {
      reader.readAsText(blob);
    } else {
      reader.readAsDataURL(blob);
    }
  };

  return (
    <Stack>
      <RenderIf condition={!!params.imageSource}>
        <img src={params.imageSource} alt="" style={{ width: 250 }} />
      </RenderIf>
      <Stack horizontal>
        <IconButton iconProps={{ iconName: 'FabricFolder' }} onClick={() => {
          (fileUploadRef?.current as unknown as HTMLInputElement).click()
        }} />
        <input type="file" name="file"
          ref={fileUploadRef} onChange={changeHandler} style={{ display: "none" }} />
        <TextField value={selectedFile?.name ?? params.value} readOnly disabled styles={{ root: { width: 186 } }} />
        {uploading ?
          <Spinner /> :
          <IconButton iconProps={{ iconName: 'CloudUpload' }} disabled={!isSelected} onClick={() => handleUploadClick()} />
        }
        <RenderIf condition={!!params.value}>
          <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => {
            if (params.onUpload) {
              params?.onUpload(0, '', '')
            }
          }} />
        </RenderIf>
      </Stack>
    </Stack>)
}

import { IAssetCategory, IZone } from "../services/assetServices";

export const flattenCategoriesTree = (cats: IAssetCategory[], result: IAssetCategory[], space: string) =>  {
  if (cats === undefined) {
    return result;
  }
  for (const cat of cats) {
    result.push({ ...cat, name: `${space}/${cat.name}` });
    if (cat.subCategories) {
      flattenCategoriesTree(cat.subCategories, result, '—' + space);
    }
  }
  return result;
}

export const flattenZonesTree = (zones: IZone[], result: IZone[], space: string) =>  {
  if (zones === undefined) {
    return result;
  }
  for (const zone of zones) {
    result.push({ ...zone, name: `${space}/${zone.name}` });
    if (zone.childZones) {
      flattenZonesTree(zone.childZones, result, '—' + space);
    }
  }
  return result;
}
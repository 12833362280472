import { formatDistance } from "date-fns";

export const formatDateToNow = (createdAt: Date | undefined): string => {
  try {
    return createdAt
      ? `${formatDistance(new Date(), new Date(createdAt + "Z"))} ago`
      : "";
  } catch (error) {
    console.warn(error);
  }
  return new Date(createdAt + "Z").toLocaleString();
};

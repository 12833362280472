import {
  CommandBar,
  FontIcon,
  ICommandBarItemProps,
  IconButton,
  Link,
  mergeStyles,
  Stack,
  Text,
} from "@fluentui/react";
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsColumnProps,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import { getAssetAudits, getMetadata, IAsset, IPagedCollection } from "../../services/assetServices";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollStackItemStyles } from "../../common/styles/StackStyles";
import React from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../../atoms/messageBarAtoms";
import { currentTenantNameAtom } from "../../atoms/authAtoms";

export interface IListItem {
  id: number;
  uuid: string;
  code: string;
  name: string;
}
export interface IListState {
  items: IListItem[];
  selectionDetails: string;
}

const sortIconClass = mergeStyles({
  fontSize: 12,
  paddingLeft: 5
});

const renderHeaderSortable = (props?: IDetailsColumnProps, defaultRender?: (props?: IDetailsColumnProps) => JSX.Element | null):
  JSX.Element | null => (!defaultRender ? <></> :
    <Stack horizontal verticalAlign="center">
      {defaultRender(props)}
      {props?.column.isSorted ? <></> :
        <FontIcon aria-label="Compass" iconName="Sort" className={sortIconClass} />
      }
    </Stack>);

const AssetsChanges = () => {
  let navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const assetId = Number.parseInt(query.get("itemId") ?? "");

  const [allItems, setData] = useState<IAsset[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [orderBy, setOrderBy] = useState<string>();
  const [shimmered, setShimmered] = useState(false);
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [refreshCount, setRefreshCount] = useState(0);

  const sortableColumnClicked = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void | undefined => {
    orderBy === column.fieldName ? setOrderBy(`${column.fieldName} Desc`) : setOrderBy(column.fieldName);
  };

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      isSorted: orderBy?.startsWith("id"),
      isSortedDescending: orderBy === "id Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 45,
      maxWidth: 50,
      isResizable: true,
      onRender: item => (
        <Link key={item.assetId} onClick={() => navigate(`/assetDetail?itemId=${item.id}`)}>
          {item.id}
        </Link >
      )
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      isSorted: orderBy?.startsWith("code"),
      isSortedDescending: orderBy === "code Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      onRender: item => !((Number.parseInt(item.updateMask) & (1 << 3))) ?
        <span>{item.code}</span> :
        <span style={{ backgroundColor: `${item.operation === 'UpdateBefore' ? 'pink' : 'lightgreen'}` }}>{item.code}</span>,
    },
    {
      key: "category",
      name: "Category",
      fieldName: "category",
      isSorted: orderBy?.startsWith("category"),
      isSortedDescending: orderBy === "category Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: item => !((Number.parseInt(item.updateMask) & (1 << 5))) ?
        <span>{item.category}</span> :
        <span style={{ backgroundColor: `${item.operation === 'UpdateBefore' ? 'pink' : 'lightgreen'}` }}>{item.category}</span>,
    },
    {
      key: "AssetDate",
      name: "Asset Date",
      fieldName: "changeDate",
      isSorted: orderBy?.startsWith("changeDate"),
      isSortedDescending: orderBy === "changeDate Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onRender: item => !((Number.parseInt(item.updateMask) & (1 << 8))) ?
        <span>{item.category}</span> :
        <span style={{ backgroundColor: `${item.operation === 'UpdateBefore' ? 'pink' : 'lightgreen'}` }}>{item.category}</span>,
    },
    {
      key: "Name",
      name: "Name",
      fieldName: "name",
      isSorted: orderBy?.startsWith("name"),
      isSortedDescending: orderBy === "name Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: item => !((Number.parseInt(item.updateMask) & (1 << 4))) ?
        <span>{item.category}</span> :
        <span style={{ backgroundColor: `${item.operation === 'UpdateBefore' ? 'pink' : 'lightgreen'}` }}>{item.category}</span>,
    },
    {
      key: 'Status',
      name: "Status",
      fieldName: "statusId",
      minWidth: 75,
      maxWidth: 75,
      onRender: (item, index, column) => !((Number.parseInt(item.updateMask) & (1 << 7))) ?
        <span>{item.statusName}</span> :
        <span style={{ backgroundColor: `${item.operation === 'UpdateBefore' ? 'pink' : 'lightgreen'}` }}>{item.statusName}</span>
    },
    {
      key: "zoneName",
      name: "Zone",
      fieldName: "zoneName",
      isSorted: orderBy?.startsWith("zoneName"),
      isSortedDescending: orderBy === "zoneName Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: item => !((Number.parseInt(item.updateMask) & (1 << 6))) ?
        <span>{item.zoneName}</span> :
        <span style={{ backgroundColor: `${item.operation === 'UpdateBefore' ? 'pink' : 'lightgreen'}` }}>{item.zoneName}</span>,
    },
  ];

  const columns = useRef<IColumn[]>(_columns);

  const _images: Map<string, string> = new Map<string, string>();

  const currentTenantName = useAtomValue(currentTenantNameAtom);

  useEffect(() => {
    const fetchData = async () => {
      context.setSelectedTab("Asset changes");
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        const metadata = await getMetadata(abortController, 'Asset');
        const otherInfoFields = metadata?.fields.filter(mf => mf.showInGrid);

        const imagePaths: string[] = [];

        const data: IPagedCollection<IAsset> = await getAssetAudits(abortController, assetId, 10, page.no)

        columns.current = columns.current.filter(c => c.key !== 'Id');

        if (columns.current.findIndex(c => c.key === 'modifiedBy') === -1) {
          columns.current.unshift(
            {
              key: 'modifiedBy',
              fieldName: 'modifiedBy',
              name: 'Modified by',
              minWidth: 100,
              maxWidth: 125,
              isResizable: true,
            }
          );
        }
        if (columns.current.findIndex(c => c.key === 'modifiedAt') === -1) {
          columns.current.unshift(
            {
              key: 'modifiedAt',
              fieldName: 'modifiedAt',
              name: 'Modified at',
              minWidth: 155,
              maxWidth: 175,
              isResizable: true,
            },
          )
        };
        if (columns.current.findIndex(c => c.key === 'operation') === -1) {
          columns.current.unshift(
            {
              key: 'operation',
              fieldName: 'operation',
              name: 'Operation',
              minWidth: 100,
              maxWidth: 125,
              isResizable: true,
            },
          )
        };

        const _assets = (data?.items?.map(asset => {
          const newAsset: any = {
            ...asset,
            changeDate: new Date(asset.changeDate + 'Z').toLocaleString(),
            modifiedAt: new Date(asset.modifiedAt + 'Z').toLocaleString(),
            modifiedBy: `${asset.modifiedBy ? `User Id: ${asset.modifiedBy}` : ''}`,
          };
          const otherInfoParts = asset.otherInfo?.split('|');
          if (otherInfoParts) {
            otherInfoFields?.forEach((mf, index) => {
              const part = otherInfoParts[index];
              newAsset[mf.name] = part;
              if (mf.fieldType === 'Image') {
                imagePaths.push(part.replace('attachments', 'thumbnails'));
              }
            });
          }
          otherInfoFields?.forEach(mf => {
            if (!columns.current?.find(column => column.name === mf.name)) {
              columns.current?.push({
                key: mf.name,
                name: mf.name,
                fieldName: mf.name,
                minWidth: 100,
                onRender: mf.fieldType === 'Image' ? (item: any) => (
                  <img src={_images.get(item[mf.name].replace('attachments', 'thumbnails'))} style={{ width: 20 }} alt="" />
                ) : mf.fieldType === 'Date' ? (item: any) => (
                  <span>{item[mf.name] ? new Date(item[mf.name]).toDateString() : ''}</span>
                ) : mf.fieldType === 'AutoComplete' ? (item: any) => (
                  <span>{(item[mf.name] ?? ":").split(':')[1]}</span>
                ) : undefined
              })
            }
          });

          return newAsset;
        }));

        setData(_assets)

        setIsLastPage(data?.isLastPage);
        setPageCount(data?.pageCount);
        setShimmered(false);
        context.setSelectedTab('Asset changes');
      }
      catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      }
      finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      }
    };

    fetchData();
  }, [page.no, page.search, orderBy, currentTenantName, refreshCount]);

  const _items: ICommandBarItemProps[] = [
    {
      key: "back",
      iconProps: { iconName: "Back" },
      iconOnly: true,
      onClick: () => {
        navigate('/');
      }
    },
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => { setRefreshCount(refreshCount + 1); },
    },
  ]

  return (
    <div>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Text variant="medium" >Change history of asset Id: {assetId}</Text>
        <span style={{ width: 20 }} />
        <span style={{ backgroundColor: 'pink' }}>Previous</span>
        <span style={{ width: 20 }} />
        <span style={{ backgroundColor: 'lightgreen' }}>Current</span>
      </Stack>
      <Stack style={{ overflowY: 'scroll' }}>
        <Stack.Item align="start" styles={scrollStackItemStyles} >
          {allItems ?
            <ShimmeredDetailsList
              items={allItems}
              columns={columns.current}
              setKey="set"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              selectionMode={SelectionMode.none}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            /> : <></>}
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item grow={1} align="center">
          <IconButton
            iconProps={{ iconName: "DoubleChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: page.no - 1, search: page.search })}
          />
          <Text>
            {page.no} of {pageCount}
          </Text>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: page.no + 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "DoubleChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: pageCount, search: page.search })}
          />
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default AssetsChanges;

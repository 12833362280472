import { ColorPicker, DefaultButton, IButtonProps, Panel, Pivot, PivotItem, PrimaryButton, Stack, TextField } from '@fluentui/react';
import * as fabric from 'fabric';
import React, { useRef } from 'react';
import { useImperativeHandle } from 'react';

export interface IPropsEditorRefType {
  refresh: () => void;
}

export interface IPropsEditorProps {
  isOpen: boolean;
  hidePropEditor: any;
  selectedObj?: fabric.fabric.Object | null;
  onChangeConfirmed: (newValues: Map<string, any>) => void;
}

const disablePivotItemProps: IButtonProps = {
  'disabled': true,
  'style': { color: 'grey' },
};

const PropsEditor: React.ForwardRefRenderFunction<IPropsEditorRefType, IPropsEditorProps> = (props: IPropsEditorProps,
  forwardedRef,
) => {
  useImperativeHandle(forwardedRef, () => ({ refresh }));
  const refresh = async () => {
  }
  const changes = useRef<Map<string, any>>(new Map<string, any>());
  const fonts = ['Arial', 'Tahoma', 'Georgia', 'Times New Roman', 'Courier New'];
  const fontSizes = ['16', '24', '28', '36', '48', '72'];

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.hidePropEditor}
      onOpened={() => changes.current.clear()}
    >
      <Stack style={{ padding: 5, height: 500, width: 250 }} tokens={{ childrenGap: 10 }}>
        <Pivot>
          <PivotItem headerText='Position' key='position'>
            <Stack>
              <TextField label='Top:' defaultValue={props.selectedObj?.top?.toString()}
                onChange={(ev, newValue) => { changes.current.set('top', newValue); }} />
              <TextField label='Left:' defaultValue={props.selectedObj?.left?.toString()}
                onChange={(ev, newValue) => { changes.current.set('left', newValue); }} />
            </Stack>
          </PivotItem>
          <PivotItem headerText='Text' key='text'
            headerButtonProps={props.selectedObj?.type !== "text" ? disablePivotItemProps : undefined}>
            <Stack tokens={{ childrenGap: 10 }}>
              <TextField label='Text:' defaultValue={(props.selectedObj as fabric.fabric.Text)?.text ?? ""}
                onChange={(ev, newValue) => {
                  changes.current.set('text', newValue);
                }} />
              <DefaultButton text="Font" menuProps={{
                items: fonts.map(font => ({
                  key: font,
                  text: font,
                  canCheck: true,
                  isChecked: ((props.selectedObj as fabric.fabric.Text)?.fontFamily) === font,
                })),
                onItemClick: (ev, item) => {
                  changes.current.set("fontFamily", item?.key);
                },
              }} />
              <DefaultButton text="Size" menuProps={{
                items: fontSizes.map(size => ({
                  key: size,
                  text: size,
                  canCheck: true,
                  isChecked: ((props.selectedObj as fabric.fabric.Text)?.fontSize)?.toString() === size,
                })),
                onItemClick: (ev, item) => {
                  changes.current.set("fontSize", item?.key);
                },
              }} />
            </Stack>
          </PivotItem>
          <PivotItem headerText='Color' key='color'>
            <Stack>
              <ColorPicker color={props.selectedObj?.fill?.toString() ?? ''} onChange={(ev, color) => {
                if (ev.type === 'mousedown' || ev.type === "input") {
                  changes.current.set('fill', color.hex);
                }
              }} />
            </Stack>
          </PivotItem>
        </Pivot>
        <PrimaryButton onClick={() => {
          props.hidePropEditor();
          props.onChangeConfirmed(changes.current);
        }}>ok</PrimaryButton>
      </Stack>
    </Panel>
  )
}

export default React.forwardRef(PropsEditor);

import { atom } from "jotai";
import { ITenant } from "../services/assetServices";
export interface IProfileData {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  email: string,
  id: string,
  roles: string[],
  permissions: number[],
  tenants: ITenant[],
  currentTenant: number,
}
export const profileDataAtom = atom<IProfileData>({
  givenName: "",
  surname: "",
  userPrincipalName: "",
  email: "",
  id: "",
  roles: [],
  permissions: [],
  tenants: [],
  currentTenant: 0,
});

export const isAuthenticatedAtom = atom<boolean>(
  (get) => !!get(profileDataAtom)
)

export const currentTenantNameAtom = atom<string>(
  (get) => {
    const currProfile = get(profileDataAtom);
    return currProfile.tenants.find(t => t.id === currProfile.currentTenant)?.name ?? '';
  }
)

import {
  CommandBar,
  ICommandBarItemProps,
  IconButton,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyles,
  MessageBarType,
  Stack,
  Text,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getQrCodes, getQrCodesDoc, getQrCodesPng, IPagedCollection, QrCodeEntityTypes } from "../../services/assetServices";
import AppContext from "../AppContext";
import { useAtomValue, useSetAtom } from "jotai";
import { clearMessageAtom, errorMessageAtom, isInProgressAtom, messageAtom } from "../../atoms/messageBarAtoms";

const QrCodes = () => {
  interface IQrCode {
    id: number;
    assetId?: number;
    zoneId?: number;
    data: string;
  }

  const [allItems, setData] = useState<IQrCode[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState<number | string | undefined>(6);
  const [page, setPage] = useState({ no: 1 });
  const [entityType, setEntityType] = useState<QrCodeEntityTypes>(QrCodeEntityTypes.Asset);

  const navigate = useNavigate();

  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const message = useAtomValue(messageAtom);

  useEffect(() => {
    context.setSelectedTab("QR Codes");
    const abortController = new AbortController();

    setIsInProgress(true);
    getQrCodes(abortController, entityType, pageSize as number, page.no)
      .then((data: IPagedCollection<IQrCode>) => {
        setData(data?.items);
        setIsLastPage(data?.isLastPage);
        setPageCount(data?.pageCount);
        clearMessage();
      })
      .catch((error) => {
        setErrorMessage(error.Message);
      })
      .finally(() => {
        setIsInProgress(false);
      });

    return () => {
      abortController.abort();
    }
  }, [page, pageSize, entityType]);

  const qrBoxClass = mergeStyles({
    border: "black",
    borderStyle: "dashed",
  });

  const wrapStackTokens: IStackTokens = { childrenGap: 30 };

  const stackStyles: IStackStyles = {
    root: {
      width: `${100}%`,
    },
  };

  const handleDownloadDocClick = () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    getQrCodesDoc(abortController, entityType)
      .then((docContent) => {
        docContent.blob().then((docBlob) => {
          const docUrl = URL.createObjectURL(docBlob);
          const anchor = document.createElement("a");
          anchor.href = docUrl;
          anchor.download = "QRCodes.docx";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(docUrl);
        })
      })
      .catch((error: any) => {
        setErrorMessage(error && error["Message"] ? error.Message : "");
      })
      .finally(() => {
        setIsInProgress(false);
      })
  }

  const handleDownloadPngClick = () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    function saveZipFile(zipFile: Blob) {
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(zipFile);
      a.href = url;
      a.download = 'QRCodesPng.zip';
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }

    getQrCodesPng(abortController, entityType)
      .then((response) => response.blob())
      .then((zipFile) => {
        saveZipFile(zipFile);
      })
      .catch((error: any) => {
        setErrorMessage(error && error["Message"] ? error.Message : "");
      })
      .finally(() => {
        setIsInProgress(false);
      })
  }

  const handleTemplateClick = () => {
      navigate(`/qrCodeTemplates?entityType=${QrCodeEntityTypes[entityType]}`);
  }

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "back",
      iconOnly: true,
      iconProps: { iconName: "ChromeBack" },
      onClick: () => navigate("/")
    },
    {
      key: "entityType",
      text: `Entity type: (${QrCodeEntityTypes[entityType]})`,
      split: true,
      subMenuProps: {
        items: [
          {
            key: "asset", text: "Asset", iconProps: { iconName: "CustomerAssets" },
            onClick: () => setEntityType(QrCodeEntityTypes.Asset),
          },
          {
            key: "zone", text: "Zone", iconProps: { iconName: "EngineeringGroup" },
            onClick: () => setEntityType(QrCodeEntityTypes.Zone)
          },
        ],

      }
    },
    {
      key: 'layout',
      text: `Layout (${pageSize})`,
      subMenuProps: {
        items: [
          { key: "6", text: "6", onClick: () => setPageSize(6) },
          { key: "12", text: "12", onClick: () => setPageSize(12) },
          { key: "24", text: "24", onClick: () => setPageSize(24) },
        ]
      }
    },
    {
      key: 'exportDoc',
      text: 'Export as Word document',
      iconOnly: true,
      iconProps: { iconName: "WordDocument" },
      onClick: handleDownloadDocClick,
    },
    {
      key: 'exportPng',
      text: 'Export as PNG',
      iconOnly: true,
      iconProps: { iconName: "FileImage" },
      onClick: handleDownloadPngClick,
    },
    {
      key: 'template',
      text: 'Template designer',
      iconOnly: true,
      iconProps: { iconName: "Design" },
      onClick: handleTemplateClick,
    }
  ]

  return (
    <div>
      {message.messageType === MessageBarType.error ? (
        <></>
      ) : (
        <>
          <CommandBar
            items={commandBarItems}
          />
          <Stack>
            <Stack horizontal>
              <Stack.Item grow={1}>
                <IconButton
                  iconProps={{ iconName: "DoubleChevronLeft" }}
                  disabled={page.no === 1}
                  onClick={() => setPage({ no: 1 })}
                />
                <IconButton
                  iconProps={{ iconName: "ChevronLeft" }}
                  disabled={page.no === 1}
                  onClick={() => setPage({ no: page.no - 1 })}
                />
                <Text>
                  {page.no} of {pageCount}
                </Text>
                <IconButton
                  iconProps={{ iconName: "ChevronRight" }}
                  disabled={isLastPage}
                  onClick={() => setPage({ no: page.no + 1 })}
                />
                <IconButton
                  iconProps={{ iconName: "DoubleChevronRight" }}
                  disabled={isLastPage}
                  onClick={() => setPage({ no: pageCount })}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
              {allItems?.map((item) => {
                const src = `data:image/png;base64,${item.data}`;
                return (
                  <Stack className={qrBoxClass}>
                    <Label>{(item?.assetId ?? item?.zoneId)}</Label>
                    <img src={src} alt="" key={item.assetId ?? item.zoneId} style={{ width: 150 }} />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </>
      )}
    </div>
  );
};

export default QrCodes;

import {
  Link,
  Panel,
  PanelType,
  SearchBox,
  DetailsList,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  getIssues,
  IPagedCollection,
  IIssue,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import {
  isInProgressAtom,
  errorMessageAtom,
} from "../../atoms/messageBarAtoms";

export interface IIssueSelectorProps {
  isOpen: boolean;
  hideIssueSelector: any;
  onIssueLinkClick: (assetId: number) => void;
}

const IssueSelector = (props: IIssueSelectorProps) => {
  const [issues, setIssues] = useState<IIssue[]>([]);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        setIssues(
          (await getIssues(abortController, 10, 1)).items.map((issue) => ({
            ...issue,
            createdAt: new Date(issue.createdAt + "Z").toLocaleString(),
          }))
        );
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
    return () => {};
  }, []);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideIssueSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick issue"
    >
      <SearchBox
        placeholder="Search issue"
        onSearch={(newValue) => {
          const abortController = new AbortController();
          getIssues(abortController, 10, 1, newValue).then(
            (assets: IPagedCollection<IIssue>) => {
              setIssues(assets.items);
            }
          );
        }}
        onClear={() => setIssues([])}
      />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item) => (
              <Link
                key={item.assetId}
                onClick={() => props.onIssueLinkClick(item.id)}
              >
                {item.id}
              </Link>
            ),
          },
          {
            key: "Comment",
            name: "Comment",
            fieldName: "comment",
            minWidth: 150,
            maxWidth: 175,
            isResizable: true,
          },
          {
            key: "CreatedAt",
            name: "Created at",
            fieldName: "createdAt",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
          },
          {
            key: "AssetId",
            name: "Asset Id",
            fieldName: "assetId",
            minWidth: 50,
            maxWidth: 75,
            isResizable: true,
          },
        ]}
        compact={true}
        items={issues}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  );
};

export default IssueSelector;

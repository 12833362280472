import {
  CommandBar,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  ICommandBarItemProps,
  IDetailsListProps,
  IDetailsRowStyles,
  IGroup,
  IconButton,
  List,
  SelectionMode,
  Stack,
  Text,
  Toggle,
  getTheme,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IAction,
  IPermission,
  IUpdatePermissionsRequest,
  getActions,
  getPermissions,
  updatePermissions,
} from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import { appRoles } from "../../authConfig";
import AppContext from "../AppContext";
import { RenderIf } from "../../libs";
import UserOrGroupSelector from "../../common/selectors/UsersOrGroupSelectors";

const PermissionsV2 = () => {
  type ItemType = { id: number; name: string; isGroup: boolean };

  const [
    isUsersSelectorOpen,
    { setTrue: showUsersSelector, setFalse: hideUsersSelector },
  ] = useBoolean(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [changed, setChanged] = useState(false);
  const [groups, setGroups] = useState<IGroup[]>();
  const [userOrGroups, setUserOrGroups] = useState<ItemType[]>();
  const [selectedUseOrGroup, setSelectedUseOrGroup] = useState<{
    isGroup: boolean;
    userOrGroupId: number;
    perms: IPermission[];
  }>();
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const context = useContext(AppContext);
  let originalItems: string | undefined = undefined;

  const profileData = useAtomValue(profileDataAtom);

  const allActions = useRef<IAction[]>([]);

  const fetchData = async () => {
    context.setSelectedTab("Permissions");
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      allActions.current = await getActions(abortController);
      const newGroups: IGroup[] = [];
      allActions.current.forEach((action: IAction) => {
        if (!newGroups.find((g: IGroup) => g.key === action.id.toString())) {
          newGroups.push({
            key: action.id.toString(),
            name: action.name,
            startIndex: 0,
            count: allActions.current.filter((a: IAction) => a.id === action.id)
              .length,
          });
        }
      });
      setGroups(newGroups);
      const allPermissions = await getPermissions(abortController);
      allPermissions.forEach((p) => {
        p.groupId = p.groupId == null ? undefined : p.groupId;
        p.userId = p.userId == null ? undefined : p.userId;
      });

      const result: any[] = [];

      allPermissions.forEach((perm) => {
        if (
          !result.find(
            (row: any) =>
              (row.isGroup && row.id === perm.groupId) ||
              (!row.isGroup && row.id === perm.userId)
          )
        ) {
          const newRow: ItemType = {
            id: perm.groupId ?? perm.userId ?? 0,
            name: perm.name,
            isGroup: !!perm.groupId,
          };
          result.push(newRow);
        }
      });
      setPermissions(allPermissions);
      setUserOrGroups(result);

      if (!originalItems) {
        originalItems = JSON.stringify(allPermissions);
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, [refreshCount]);

  const handleSaveClick = () => {
    const payload: IUpdatePermissionsRequest = {
      permissions: [],
    };
    permissions.forEach((perm: IPermission) => {
      const currPerm = payload.permissions.find(
        (p) => p.groupId === perm.groupId && p.userId === perm.userId
      );
      if (!currPerm) {
        payload.permissions.push({
          userId: perm.userId ? perm.userId : undefined,
          groupId: perm.groupId ? perm.groupId : undefined,
          actions: [perm.actionId],
        });
      } else {
        currPerm.actions.push(perm.actionId);
      }
    });

    const abortController = new AbortController();

    setIsInProgress(true);
    updatePermissions(abortController, payload)
      .then((data) => {
        if (data.message === "OK") {
          setSuccessMessage("Permission updated successfully.");
          originalItems = JSON.stringify(permissions);
          setChanged(false);
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsInProgress(false);
        setSelectedUseOrGroup(undefined);
      });
  };

  const handleDeleteClick = () => {
    if (!userOrGroups) {
      return;
    }
    const index = userOrGroups.findIndex(
      (ug) =>
        ug.id === selectedUseOrGroup?.userOrGroupId &&
        ug.isGroup === selectedUseOrGroup.isGroup
    );
    const newUserOrGroups = [...userOrGroups];
    newUserOrGroups.splice(index, 1);
    setUserOrGroups(newUserOrGroups);
    const newPermissions = permissions.filter(
      (p: IPermission) =>
        !(
          (selectedUseOrGroup?.isGroup ? p.groupId : p.userId) ===
          selectedUseOrGroup?.userOrGroupId
        )
    );
    setPermissions(newPermissions);
    setChanged(true);
    setSelectedUseOrGroup(undefined);
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: () => showUsersSelector(),
    },
    {
      key: "refresh",
      text: "Refresh",
      iconProps: { iconName: "Refresh" },
      onClick: () => {
        setSelectedUseOrGroup(undefined);
        setRefreshCount(refreshCount + 1);
      },
    },
    {
      key: "save",
      text: "Save",
      iconProps: { iconName: "Save" },
      disabled: !profileData.roles.includes(appRoles.Admin) || !changed,
      onClick: () => handleSaveClick(),
    },
    {
      key: "delete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => handleDeleteClick(),
    },
  ];

  const columns: IColumn[] = [
    {
      key: "name",
      fieldName: "name",
      name: "User|Group Name",
      minWidth: 120,
    },
    {
      key: "id",
      fieldName: "id",
      name: "User|Group Id",
      minWidth: 110,
      onRender(item, index, column) {
        return (
          <Stack horizontal grow={true} tokens={{ childrenGap: 5 }}>
            <Text variant="large">{item.id}</Text>
            <Text variant="small">{item.isGroup ? `(Group)` : `(User)`}</Text>
          </Stack>
        );
      },
    },
    {
      key: "action",
      minWidth: 25,
      name: "",
      onRender(item) {
        return (
          <IconButton
            iconProps={{
              iconName: !selectedUseOrGroup
                ? "Edit"
                : selectedUseOrGroup?.isGroup === item.isGroup &&
                  selectedUseOrGroup?.userOrGroupId === item.id
                ? "ChevronRight"
                : "Edit",
            }}
            onClick={() =>
              handleUserOrGroupSelectionClicked(item.isGroup, item.id)
            }
          />
        );
      },
    },
  ];

  const handleUserOrGroupSelectionClicked = (
    isGroup: boolean,
    userOrGroupId: number
  ) => {
    const userOrGroupPerms = permissions.filter(
      (p) => (isGroup ? p.groupId : p.userId) === userOrGroupId
    );
    setSelectedUseOrGroup({
      isGroup: isGroup,
      userOrGroupId: userOrGroupId,
      perms: userOrGroupPerms,
    });
  };

  const handlePermChange = (action: IAction, checked?: boolean) => {
    if (checked) {
      const newPermission: IPermission = {
        id: 0,
        actionId: action.id,
        userId: selectedUseOrGroup?.isGroup
          ? undefined
          : selectedUseOrGroup?.userOrGroupId,
        groupId: selectedUseOrGroup?.isGroup
          ? selectedUseOrGroup.userOrGroupId
          : undefined,
        name: "",
      };
      const newPermissions = [...permissions];
      newPermissions.push(newPermission);
      const newUserOrGroupPerms = selectedUseOrGroup?.perms ?? [];
      newUserOrGroupPerms.push(newPermission);
      setPermissions(newPermissions);
      setChanged(JSON.stringify(newPermissions) !== originalItems);
      setSelectedUseOrGroup({
        isGroup: selectedUseOrGroup?.isGroup ?? false,
        userOrGroupId: selectedUseOrGroup?.userOrGroupId ?? 0,
        perms: newUserOrGroupPerms,
      });
    } else if (!checked) {
      const index = selectedUseOrGroup?.perms.findIndex(
        (p: IPermission) => p.actionId === action.id
      );
      if (index && index > -1) {
        const newUserOrGroupPerms = selectedUseOrGroup?.perms ?? [];
        newUserOrGroupPerms.splice(index, 1);
        setSelectedUseOrGroup({
          isGroup: selectedUseOrGroup?.isGroup ?? false,
          userOrGroupId: selectedUseOrGroup?.userOrGroupId ?? 0,
          perms: newUserOrGroupPerms,
        });
      }
      const indexPerm = permissions.findIndex(
        (p: IPermission) =>
          (selectedUseOrGroup?.isGroup ? p.groupId : p.userId) ===
            selectedUseOrGroup?.userOrGroupId && action.id === p.actionId
      );
      if (indexPerm && indexPerm > -1) {
        const newPermissions = [...permissions];
        newPermissions.splice(indexPerm, 1);
        setPermissions(newPermissions);
        setChanged(JSON.stringify(newPermissions) !== originalItems);
      }
    }
  };

  const onRenderCell = (
    // nestingDepth?: number,
    item?: IAction,
    itemIndex?: number
  ): React.ReactNode => {
    if (!item || typeof itemIndex !== "number" || itemIndex === -1) {
      return null;
    }
    const selectedPerm = selectedUseOrGroup?.perms.find(
      (p) => p.actionId === item.id
    );
    return (
      <>
        <Toggle
          inlineLabel
          //   label={JSON.stringify(item)}
          label={item.name}
          checked={!!selectedPerm}
          onChange={(_, checked?: boolean) => handlePermChange(item, checked)}
        />
      </>
    );
  };

  const theme = getTheme();

  const onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (
        props.item.id === selectedUseOrGroup?.userOrGroupId &&
        props.item.isGroup === selectedUseOrGroup?.isGroup
      ) {
        customStyles.root = {
          backgroundColor: theme.palette.themeLighterAlt,
        };
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={commandBarItems}
          styles={{ root: { width: 550 } }}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
      </Stack>
      <Stack horizontal>
        <DetailsList
          items={userOrGroups ?? []}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
          selectionPreservedOnEmptyClick={true}
          onRenderRow={onRenderRow}
        />
        <RenderIf condition={!!selectedUseOrGroup}>
          <List
            items={[...allActions.current]}
            // eslint-disable-next-line react/jsx-no-bind
            onRenderCell={onRenderCell}
            //   selection={selection}
            // selectionMode={SelectionMode.none}
            // groups={groups}
            // compact={true}
          />
        </RenderIf>
      </Stack>
      <UserOrGroupSelector
        isOpen={isUsersSelectorOpen}
        hideUsersAndGroupsSelector={hideUsersSelector}
        onUserOrGroupSelected={(
          id: number,
          isGroup: boolean,
          username?: string
        ) => {
          if (userOrGroups) {
            setUserOrGroups([
              ...userOrGroups,
              { id: id, name: username ?? "", isGroup: isGroup },
            ]);
          }
          setChanged(true);
        }}
      />
    </>
  );
};

export default PermissionsV2;

import {
  Link,
  Panel,
  PanelType,
  SearchBox,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IconButton,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  activeQrCodeTemplate,
  deleteQrCodeTemplate,
  getQrCodesTemplates,
  IPagedCollection,
  IQrCodesTemplate,
} from "../../services/assetServices";
import {
  errorMessageAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import { useSetAtom } from "jotai";

export interface IQrCodesTemplateSelectorProps {
  isOpen: boolean;
  hideQrCodesTemplateSelector: any;
  entityType: string;
  onTemplateSelected: (templateId: number, templateName: string) => void;
}

const QrCodesTemplateSelector = (props: IQrCodesTemplateSelectorProps) => {
  const [qrCodesTemplates, setQrCodesTemplates] = useState<IQrCodesTemplate[]>(
    []
  );
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [search, setSearch] = useState<string>();
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    getQrCodesTemplates(
      abortController,
      props.entityType,
      10,
      1,
      undefined,
      search
    )
      .then((qrCodesTemplates: IPagedCollection<IQrCodesTemplate>) => {
        setQrCodesTemplates(
          qrCodesTemplates.items.map((qr: any) => ({
            ...qr,
            createdAt: new Date(qr.createdAt + "Z").toLocaleString(),
          }))
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(`Error: ${error}`);
      });
  }, [search, refreshCount]);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideQrCodesTemplateSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick QrCodesTemplate for selected issues"
    >
      <SearchBox
        placeholder="Search QrCodesTemplate"
        onSearch={(newValue) => {
          setSearch(newValue);
        }}
        onClear={() => setSearch(undefined)}
      />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 20,
            maxWidth: 25,
            isResizable: true,
            onRender: (item) => (
              <Link
                key={item.QrCodesTemplateId}
                onClick={() => props.onTemplateSelected(item.id, item.name)}
              >
                {item.id}
              </Link>
            ),
          },
          {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 175,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: "Version",
            name: "Version",
            fieldName: "version",
            minWidth: 25,
            maxWidth: 50,
            isResizable: true,
          },
          {
            key: "CreatedAt",
            name: "Created at",
            fieldName: "createdAt",
            minWidth: 75,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "Actions",
            name: "",
            minWidth: 100,
            isResizable: false,
            onRender: (item) => {
              return (
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                      var abortController = new AbortController();
                      deleteQrCodeTemplate(abortController, item.id)
                        .then(() => {
                          setSuccessMessage(
                            `Successfully delete template id: ${item.id}`
                          );
                          setRefreshCount(refreshCount + 1);
                        })
                        .catch((error: any) => setErrorMessage(error.message));
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: item.isActive ? "FavoriteStarFill" : "FavoriteStar" }}
                    onClick={() => {
                      var abortController = new AbortController();
                      activeQrCodeTemplate(abortController, item.id)
                        .then(() => {
                          setSuccessMessage(
                            `Template id ${item.id} is now the active template`
                          );
                          setRefreshCount(refreshCount + 1);
                        })
                        .catch((error: any) => setErrorMessage(error.message));
                    }}
                  />
                </Stack>
              );
            },
          },
        ]}
        compact={true}
        items={qrCodesTemplates}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  );
};

export default QrCodesTemplateSelector;

import { useContext, useEffect, useRef, useState } from "react";
import PagedList, {
  IListPageColumn,
  IPagedListRefType,
} from "../../common/PagedList";
import {
  IField,
  IWorkOrderAssignmentRequest,
  IWorkOrderStatuses,
  createWorkOrderAssignment,
  deleteGroup,
  getChartsData,
  getMetadata,
  getWorkOrderAssignment,
  getWorkOrderStatuses,
  getWorkOrders,
} from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { profileDataAtom } from "../../atoms/authAtoms";
import {
  clearMessageAtom,
  errorMessageAtom,
  isInProgressAtom,
  messageBarAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import { appRoles } from "../../authConfig";
import { useBoolean } from "@fluentui/react-hooks";
import ContractorSelector from "../../common/selectors/ContractorSelector";
import {
  FontIcon,
  Link,
  MessageBarType,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import AppContext from "../AppContext";

export interface WorkOrdersV2Params {
  contractorId?: number;
  hideEditButtons?: boolean;
  hideExtraColumns?: boolean;
}

const WorkOrdersV2 = (params: WorkOrdersV2Params) => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setMessageBar = useSetAtom(messageBarAtom);

  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<number>();
  const [
    isContractorSelectorOpen,
    { setTrue: showContractorSelector, setFalse: hideContractorSelector },
  ] = useBoolean(false);

  const [workOrderStatuses, setWorkOrderStatuses] = useState<
    IWorkOrderStatuses[]
  >([]);
  const pagedListRef = useRef<IPagedListRefType>(null);

  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const [assigning, setAssigning] = useState(false);
  const [otherInfoColumns, setOtherInfoColumns] = useState<IListPageColumn[]>(
    []
  );
  const [otherInfoFieldNames, setOtherInfoFieldNames] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);

      try {
        const abortController = new AbortController();

        const woStatuses: IWorkOrderStatuses[] = await getWorkOrderStatuses(
          abortController
        );
        setWorkOrderStatuses(woStatuses);
        const metadata = await getMetadata(abortController, "WorkOrder");
        const otherInfoColumns = metadata?.fields
          .filter((mf: IField) => mf.showInGrid)
          .map((oif: IField) => ({
            key: oif.name,
            name: oif.name,
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
          }));
        setOtherInfoColumns(otherInfoColumns);
        const otherInfoFieldNames: string[] = otherInfoColumns.map(
          (oic: IListPageColumn) => {
            return oic.name ?? "";
          }
        );
        setOtherInfoFieldNames(otherInfoFieldNames);

        clearMessage();
        const chartData = await getChartsData(
          abortController,
          "workOrders",
          ""
        );
        if (params.contractorId) {
          context.setSelectedTab("Contractors");
        } else {
          context.setSelectedTab("Work orders", chartData.values);
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  const baseColumns: IListPageColumn[] = [
    {
      key: "id",
      name: "Id",
      minWidth: 25,
      maxWidth: 40,
      isResizable: true,
    },
    {
      key: "code",
      name: "Code",
      minWidth: 75,
      maxWidth: 85,
      isResizable: true,
    },
    {
      key: "description",
      name: "Description",
      minWidth: 190,
      maxWidth: 265,
      isResizable: true,
    },
    {
      key: "status",
      name: "Status",
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
      onRender: (item, index, column) => {
        const workOrderStatus = workOrderStatuses.find(
          (wos) => wos.id === item.statusId
        );
        return (
          <Stack
            horizontal
            style={{
              borderBottomColor: `${workOrderStatus?.color}`,
              borderBlockStyle: "solid",
              borderTopStyle: "none",
            }}
          >
            <Stack.Item align="start">
              <span style={{ display: "flex", justifyContent: "left" }}>
                {workOrderStatus?.code}
              </span>
            </Stack.Item>
            <Stack.Item align="stretch" grow={1}>
              <span style={{ width: "25px" }}></span>{" "}
            </Stack.Item>
            <Stack.Item align="end">
              <TooltipHost content={workOrderStatus?.name}>
                <FontIcon iconName={workOrderStatus?.icon} />
              </TooltipHost>
            </Stack.Item>
          </Stack>
        );
      },
    },
    {
      key: "createdAt",
      name: "Created at",
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
    },
  ];

  const extraColumns = [
    {
      key: "assetCategory",
      name: "Category",
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
    },
    {
      key: "zoneName",
      name: "Zone",
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
    },
    {
      key: "issueId",
      name: "Issue Id",
      minWidth: 50,
      maxWidth: 75,
      isResizable: true,
      onRender: (item: any) => (
        <Link onClick={() => navigate(`/editIssue?id=${item.id}`)}>
          {item.id}
        </Link>
      ),
    },
  ];

  return (
    <>
      <PagedList
        selectedTab="WorkOrders"
        ref={pagedListRef}
        listHeigh={params.contractorId ? 425 : 525}
        columns={[
          ...baseColumns,
          ...(!params.hideExtraColumns ? extraColumns : []),
          ...(!params.hideExtraColumns ? otherInfoColumns : []),
        ]}
        otherInfoFieldNames={otherInfoFieldNames}
        getAction={async (abortController, pageSize, pageNo) => {
          const data = !params.contractorId
            ? await getWorkOrders(abortController, pageSize, pageNo)
            : await getWorkOrderAssignment(
                abortController,
                params.contractorId,
                pageSize,
                pageNo
              );
          data.items = data.items.map((wo) => ({
            ...wo,
            createdAt: new Date(wo.createdAt + "Z").toLocaleString(),
          }));
          return data;
        }}
        commandBarItems={
          params.hideEditButtons
            ? []
            : [
                {
                  key: "newWorkOrder",
                  text: "New",
                  iconProps: { iconName: "Add" },
                  onClick: () => navigate("/newWorkOrder"),
                  disabled: !profileData.roles.includes(appRoles.Admin),
                },
                {
                  key: "editWorkOrder",
                  text: "Edit",
                  iconProps: { iconName: "Edit" },
                  onClick: () =>
                    navigate(`/editWorkOrder?id=${selectedWorkOrderId}`),
                  disabled:
                    !profileData.roles.includes(appRoles.Admin) ||
                    !selectedWorkOrderId,
                },
                {
                  key: "resendWorkOrder",
                  text: "Resend",
                  iconProps: { iconName: "FabricSyncFolder" },
                  onClick: () => {
                    if (selectedWorkOrderId) {
                      const abortController = new AbortController();
                      deleteGroup(
                        abortController,
                        Number(selectedWorkOrderId)
                      ).then(() => {
                        pagedListRef.current?.refresh();
                        setSuccessMessage(
                          `Group with id: ${selectedWorkOrderId} deleted.`
                        );
                      });
                    }
                  },
                  disabled:
                    !profileData.roles.includes(appRoles.Admin) ||
                    !selectedWorkOrderId,
                },
                {
                  key: "assignWorkOrder",
                  text: "Assign",
                  iconProps: { iconName: "Assign" },
                  disabled:
                    !profileData.roles.includes(appRoles.Admin) ||
                    !selectedWorkOrderId ||
                    assigning,
                  onClick: () => {
                    showContractorSelector();
                  },
                },
              ]
        }
        onSelectionChange={(selection: any) => {
          setSelectedWorkOrderId(Number(selection.id));
        }}
      ></PagedList>
      <ContractorSelector
        isOpen={isContractorSelectorOpen}
        hideContractorSelector={hideContractorSelector}
        onContractorSelected={(id: number) => {
          if (selectedWorkOrderId) {
            setMessageBar("Assigning", MessageBarType.info);
            setAssigning(true);
            const abortController = new AbortController();
            const workOrderAssignment: IWorkOrderAssignmentRequest = {
              contractorId: id,
              workOrderId: selectedWorkOrderId,
              description: "",
            };
            createWorkOrderAssignment(abortController, workOrderAssignment);
            setAssigning(false);
          }
        }}
      />
    </>
  );
};

export default WorkOrdersV2;

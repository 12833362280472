import { useRef } from "react";
import EditItem from "../../common/EditItem";
import {
  createReferenceValue,
  editReferenceValue,
  getReferenceValue,
} from "../../services/assetServices";
import { useLocation } from "react-router-dom";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";

const ReferenceValue = () => {
  const isSaved = useRef(false);
  const query = new URLSearchParams(useLocation().search);
  const refName = query.get("refName") ?? "";
  const setSuccessMessage = useSetAtom(successMessageAtom);

  return (
    <>
      <div>Reference Value</div>
      <EditItem
        getAction={(abortController, id) =>
          getReferenceValue(abortController, id)
        }
        newAction={async (abortController, newReferenceValue) => {
          const createResponse = createReferenceValue(abortController, {
            refName: newReferenceValue.refName,
            key: newReferenceValue.key,
            value: newReferenceValue.value,
            parentRef: newReferenceValue.parentRef,
            parentKey: newReferenceValue.parentKey,
          });
          setSuccessMessage(
            `Reference value with id: ${(await createResponse).referenceValueId} created.`
          );
        }}
        editAction={async (abortController, id, editedRefValue) => {
          editReferenceValue(abortController, id, {
            value: editedRefValue.value,
            parentRef: editedRefValue.parentRef,
            parentKey: editedRefValue.parentKey,
          });
          setSuccessMessage(`Reference value with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/settings/references"}
        metadata={{
          fields: [
            {
              name: "refName",
              fieldType: "String",
              label: "Reference",
            },
            {
              name: "key",
              fieldType: "String",
              label: "Key",
            },
            {
              name: "value",
              fieldType: "String",
              label: "Value",
            },
            {
              name: "parentRef",
              fieldType: "String",
              label: "Parent Ref",
            },
            {
              name: "parentKey",
              fieldType: "String",
              label: "Parent Key",
            },
          ],
          lookups: [],
          validations: [],
        }}
      />
    </>
  );
};

export default ReferenceValue;

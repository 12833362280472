import { Link, Panel, PanelType, SearchBox, DetailsList, SelectionMode, DetailsListLayoutMode, Stack, StackItem, ActionButton } from '@fluentui/react';
import { useEffect, useState } from 'react'
import { getFiles, IPagedCollection, IFile, getImage, IImage } from '../../services/assetServices';
import { errorMessageAtom } from '../../atoms/messageBarAtoms';
import { useSetAtom } from 'jotai';
import { FileSelector } from '../../common/FileSelector';

export interface IQrCodesTemplateImagesProps {
  isOpen: boolean;
  hideQrCodesTemplateImages: any;
  onImageSelected: (url: string) => void;
}

const QrCodesTemplateImages = (props: IQrCodesTemplateImagesProps) => {
  const [qrCodesImages, setQrCodesImages] = useState<IFile[]>([]);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [search, setSearch] = useState<string>();
  const [addingImage, setAddingImage] = useState(false);

  const [imageSource, setImageSource] = useState<string>();

  useEffect(() => {
    const abortController = new AbortController();
    getFiles(abortController, 'QRCodeTemplate', 10, 1, undefined, search)
      .then((qrCodesTemplates: IPagedCollection<IFile>) => {
        setQrCodesImages(qrCodesTemplates.items.map((qr: any) => (
          {
            ...qr,
            purpose: 'QRCodeTemplate',
            createdAt: new Date(qr.createdAt + 'Z').toLocaleString(),
          }
        )));
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(`Error: ${error}`);
      })
  }, [search])

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideQrCodesTemplateImages}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick an image"
    >
      <Stack horizontal>
        <StackItem grow>
          <ActionButton iconProps={{ iconName: "Photo2Add" }}
            onClick={() => setAddingImage(true)}
          >Add image</ActionButton>
        </StackItem>
        <SearchBox
          placeholder="Search images"
          onSearch={(newValue) => {
            setSearch(newValue);
          }}
          onClear={() => setSearch(undefined)} />
        <div style={{ display: addingImage ? '' : 'none' }}>
          <FileSelector
            value={''}
            uploadImage={true}
            purpose='QRCodeTemplate'
            onUpload={(id: number, url: string, name: string) => {
              const newQrCodesImages = [...qrCodesImages];
              newQrCodesImages.push({ id: id, name: name, purpose: 'QRCodeTemplate', url: url, });
              setQrCodesImages(newQrCodesImages);
              setAddingImage(false);
            }}
          />
        </div>
      </Stack>

      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 20,
            maxWidth: 25,
            isResizable: true,
            onRender: item => (
              <Link key={item.QrCodesTemplateId}
                onClick={() => {
                  const abortController = new AbortController();
                  getImage(abortController, item.url, 'attachments')
                    .then((data: IImage) => {
                      setImageSource(data.imageContent);
                    })
                  props.onImageSelected(item.url)
                }}
              >{item.id}</Link >
            )
          },
          {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 225,
            maxWidth: 275,
            isResizable: true,
          },
          {
            key: "CreatedAt",
            name: "Created at",
            fieldName: "createdAt",
            minWidth: 75,
            maxWidth: 125,
            isResizable: true,
          },
        ]}
        compact={true}
        items={qrCodesImages}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        selectionMode={SelectionMode.none}
      />
      <img src={imageSource} alt='x' />
    </Panel >
  )
}

export default QrCodesTemplateImages
import { useContext, useEffect, useRef, useState } from 'react'
import AppContext from '../AppContext';
import { CommandBar, ContextualMenuItemType, DefaultButton, DialogFooter, ICommandBarItemProps, Modal, VerticalDivider, mergeStyles } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import * as fabric from 'fabric';
import { useLocation, useNavigate } from 'react-router-dom';
import { IImage, IRestoreQrCodeResponse, IStoreQrCodeResponse, getImage, getMetadata, restoreQrCodeTemplate, storeQrCodeTemplate } from '../../services/assetServices';
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from '../../atoms/messageBarAtoms';
import { useSetAtom } from 'jotai';
import QrCodesTemplateSelector from './QrCodesTemplateSelector';
import QrCodesTemplateImages from './QrCodesTemplateImages';
import InputDialog from '../../common/InputDialog';
import ReactDiffViewer from 'react-diff-viewer-continued';
import PropsEditor from './PropsEditor';

const QrCodesTemplates = () => {
  const query = new URLSearchParams(useLocation().search);
  const entityType = query.get("entityType");

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [name, setName] = useState<string>();
  const [isQrCodesTemplateSelectorOpen, { setTrue: showQrCodesTemplateSelector, setFalse: hideQrCodesTemplateSelector }] = useBoolean(false);
  const [isQrCodesImageSelectorOpen, { setTrue: showQrCodesImageSelector, setFalse: hideQrCodesImageSelector }] = useBoolean(false);
  const [isNameInputOpen, { setTrue: showNameInput, setFalse: hideNameInput }] = useBoolean(false);
  const [isDiffDialogOpen, { setTrue: showDiffDialog, setFalse: hideDiffDialog }] = useBoolean(false);
  const [isPropModalOpen, { setTrue: showPropModal, setFalse: hidePropModal }] = useBoolean(false);

  const [fields, setFields] = useState<{ key: string, text: string, otherInfo?: boolean }[]>();

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        const baseFields = [];
        if (entityType === 'Asset') {
          baseFields.push(
            { key: 'AssetName', text: 'Asset name' },
            { key: 'AssetId', text: 'Asset Id' },
          );
          const metadata = await getMetadata(abortController, 'Asset');
          const otherInfoFields = metadata?.fields.filter(mf => ['String', 'Number', 'Date', 'Lookup'].includes(mf.fieldType));
          setFields(baseFields.concat(otherInfoFields.map(field => ({ key: field.name, text: field.name, otherInfo: true, }))));
        } else {
          baseFields.push(
            { key: 'ZoneName', text: 'Zone name' },
            { key: 'ZoneId', text: 'Zone Id' },
          );
          setFields(baseFields);
        }
      }
      catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      }
      finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      }
    };

    fetchData();
    context.setSelectedTab("QR Codes template designer");
  }, [])

  const { editor, onReady } = useFabricJSEditor();

  function loadImage(url: string, canvas: any) {
    fabric.fabric.Image.fromURL(url, (img) => {
      canvas.add(img);
    });
  }

  const addImage = (url: string) => {
    const abortController = new AbortController();
    getImage(abortController, url, "attachments")
      .then((data: IImage) => {
        const imgElement = document.createElement("img");
        imgElement.src = data.imageContent;
        const image = new fabric.fabric.Image(imgElement, {
          left: 100,
          top: 100,
          width: 100,
          height: 100,
        });
        image.setSrc(data.imageContent, (img: any) => {
          console.log(JSON.stringify(img));
          editor?.canvas.add(img);
        });
      })
  }

  const handleNewClick = () => {
    showNameInput();
  }

  const handleAddQrClick = () => {
    loadImage('MoKoSolution-QR-5.png', editor?.canvas);
  }

  const handleSaveClick = () => {
    if (!name) {
      showNameInput();
      return;
    }
    fromSaveAs.current = false;
    saveTemplate(name);
  }

  const saveTemplate = (templateName: string) => {
    const abortController = new AbortController();
    storeQrCodeTemplate(abortController, entityType ?? "asset", JSON.stringify(editor?.canvas.toJSON()), isActive.current, templateName)
      .then((response: IStoreQrCodeResponse) => {
        setSuccessMessage(`QrCode template successfully saved, id:${response.id}, version: ${response.version}`);
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(`Error: ${error}`);
      })
  }

  const handleSaveAsClick = () => {
    fromSaveAs.current = true;
    showNameInput();
  }

  const handleDiffClick = () => {
    showDiffDialog();
  }

  const originalTemplateCode = useRef<string>();
  const isActive = useRef<boolean>(false);

  const openTemplate = (templateId: number, templateName: string) => {
    const abortController = new AbortController();
    restoreQrCodeTemplate(abortController, templateId)
      .then((data: IRestoreQrCodeResponse) => {
        setName(templateName);
        originalTemplateCode.current = data.content;
        isActive.current = data.isActive;
        editor?.canvas.loadFromJSON(data.content, () => {
          setSuccessMessage(`QrCode template loaded.`);
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(`Error: ${error}`);
      })
  }

  function addText(text: string) {
    return () => {
      editor?.canvas.add(new fabric.fabric.Text(text, {
        fontFamily: 'Arial', fontSize: 16, lockScalingX: true, lockScalingY: true,
        lockRotation: true,
      }));
    };
  }

  const [selectedTextFont, setSelectedTextFont] = useState<string>();
  const [selectedTextSize, setSelectedTextSize] = useState<number>();

  const fromSaveAs = useRef<boolean>(false);

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'back',
      text: 'Back',
      iconOnly: true,
      iconProps: { iconName: 'ChromeBack' },
      onClick: () => navigate('/qrCodes'),
    },
    {
      key: 'new',
      text: 'New',
      iconOnly: true,
      iconProps: { iconName: 'FabricNewFolder' },
      onClick: handleNewClick,
    },
    {
      key: 'open',
      text: 'Open',
      iconOnly: true,
      iconProps: { iconName: "OpenFolderHorizontal" },
      onClick: showQrCodesTemplateSelector,
    },
    {
      key: 'save',
      text: 'Save',
      iconOnly: true,
      iconProps: { iconName: "Save" },
      onClick: handleSaveClick,
    },
    {
      key: 'saveAs',
      text: 'Save as',
      iconOnly: true,
      iconProps: { iconName: "SaveAs" },
      onClick: handleSaveAsClick,
    },
    {
      key: 'diff',
      text: 'Diff',
      iconOnly: true,
      iconProps: { iconName: "DiffSideBySide" },
      onClick: handleDiffClick,
    },
    { key: "divider1", itemType: ContextualMenuItemType.Divider, onRender: () => <VerticalDivider /> },
    {
      key: 'addField',
      text: 'Add field',
      iconProps: { iconName: 'NumberSymbol' },
      subMenuProps: {
        items: [
          {
            key: 'baseFields',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
              title: 'Base fields',
              bottomDivider: true,
              items: fields ? fields?.filter(field => !field.otherInfo).map(field => ({
                key: field.key,
                text: field.text,
                onClick: addText(`#${field.key}#`),
              })) : [],
            },
          },
          {
            key: 'otherInfoFields',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
              title: 'Company specific fields',
              items: fields ? fields?.filter(field => field.otherInfo).map(field => ({
                key: field.key,
                text: field.text,
                onClick: addText(`#${field.key}#`),
              })) : [],
            }
          }
        ],
      }
    },
    {
      key: 'addImage',
      text: 'Add image',
      iconOnly: true,
      iconProps: { iconName: "FileImage" },
      onClick: showQrCodesImageSelector,
    },
    {
      key: 'rectangle',
      text: 'Add rectangle',
      iconOnly: true,
      iconProps: { iconName: "RectangleShape" },
      onClick: () => editor?.addRectangle(),
    },
    {
      key: 'addQrCode',
      text: 'Add QR code',
      iconOnly: true,
      iconProps: { iconName: "QRCode" },
      onClick: handleAddQrClick,
    },
    {
      key: 'text',
      text: 'Add text',
      iconOnly: true,
      iconProps: { iconName: "TextField" },
      onClick: () => editor?.addText("Text"),
    },
    {
      key: 'arrange',
      text: 'Arrange',
      iconProps: { iconName: "ArrangeSendToBack" },
      subMenuProps: {
        items: [
          {
            key: 'sendToBack',
            iconProps: { iconName: 'ArrangeSendToBack' },
            text: 'Send to back',
            onClick: () => {
              editor?.canvas?.getActiveObject()?.sendToBack();
            },
          },
          {
            key: 'sendBackward',
            iconProps: { iconName: 'ArrangeSendBackward' },
            text: 'Send backward',
            onClick: () => editor?.canvas?.getActiveObject()?.sendBackwards(true),
          },
          {
            key: 'bringForward',
            iconProps: { iconName: 'ArrangeBringForward' },
            text: 'Bring forward',
            onClick: () => editor?.canvas?.getActiveObject()?.bringForward(true),
          },
          {
            key: 'bringToFront',
            iconProps: { iconName: 'ArrangeBringToFront' },
            text: 'Bring to front',
            onClick: () => editor?.canvas?.getActiveObject()?.bringToFront(),
          },
        ]
      }
    },
    {
      key: 'delete',
      text: 'Delete',
      iconOnly: true,
      iconProps: { iconName: "Delete" },
      disabled: (!editor?.canvas.getActiveObject()),
      onClick: () => editor?.deleteSelected(),
    },
    {
      key: 'props',
      text: 'Properties',
      iconOnly: true,
      iconProps: { iconName: "CustomList" },
      disabled: (!editor?.canvas.getActiveObject()),
      onClick: () => showPropModal(),
    },
  ]

  const sampleCanvas = mergeStyles({
    height: 500,
    width: 500,
    borderWidth: 1,
    borderStyle: 'solid',
  });

  return (
    <div id="qrTemplateDiv">
      <CommandBar items={commandBarItems} />
      <FabricJSCanvas className={sampleCanvas} onReady={onReady} />
      <PropsEditor
        hidePropEditor={hidePropModal}
        isOpen={isPropModalOpen}
        selectedObj={editor?.canvas?.getActiveObject()}
        onChangeConfirmed={(changes: Map<string, any>) => {
          if (editor?.canvas.getActiveObject()) {
            const activeObject: fabric.fabric.Object = editor?.canvas.getActiveObject() ?? new fabric.fabric.Object();
            changes.forEach((value, key) => {
              if (key === 'left') {
                activeObject.left = Number.parseInt(value);
              } else if (key === 'top') {
                activeObject.top = Number.parseInt(value);
              } else if (key === 'fill') {
                editor?.setFillColor(`#${value}`);
              } else if (key === 'text') {
                (editor?.canvas.getActiveObject() as fabric.fabric.Text).text = value;
              } else if (key === "fontFamily") {
                (editor?.canvas.getActiveObject() as fabric.fabric.Text).fontFamily = value;
              } else if (key === "fontSize") {
                (editor?.canvas.getActiveObject() as fabric.fabric.Text).fontSize = Number.parseInt(value);
              }
            })
            editor?.canvas.requestRenderAll();
          }
        }}
      />
      <QrCodesTemplateSelector
        isOpen={isQrCodesTemplateSelectorOpen}
        hideQrCodesTemplateSelector={hideQrCodesTemplateSelector}
        entityType={entityType ?? 'asset'}
        onTemplateSelected={(templateId: number, templateName: string) => {
          openTemplate(templateId, templateName);
        }} />
      <QrCodesTemplateImages
        isOpen={isQrCodesImageSelectorOpen}
        hideQrCodesTemplateImages={hideQrCodesImageSelector}
        onImageSelected={(url: string) => {
          addImage(url);
        }} />
      <InputDialog
        message={'Enter template name'}
        isModalOpen={isNameInputOpen}
        hideModal={hideNameInput}
        onInputConfirmed={(newValue) => {
          setName(newValue);
          if (fromSaveAs.current) {
            if (newValue) {
              saveTemplate(newValue);
            }
          }
        }}
      />
      <Modal isOpen={isDiffDialogOpen} onDismiss={hideDiffDialog}>
        <div style={{ height: 500, overflow: "auto" }}>
          <ReactDiffViewer
            oldValue={JSON.stringify(JSON.parse(originalTemplateCode.current ?? "{}"), null, 2)}
            newValue={JSON.stringify(editor?.canvas.toJSON(), null, 2)}
            splitView={true}
          />
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={hideDiffDialog}
            text="Close" />
        </DialogFooter>
      </Modal>
    </div>
  )
}

export default QrCodesTemplates
import { FontIcon, Text } from "@fluentui/react";

export interface ILoadingSignProps {
  message: string;
  iconName: string;
  width?: number;
  height?: number;
}

const LoadingSign = (props: ILoadingSignProps) => {
  return (
    <div
      style={{
        width: props.width ?? 800,
        height: props.height ?? 500,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ flex: "auto" }}>
        <Text
          variant="xLarge"
          styles={{
            root: { position: "relative", color: "gray", top: -75, left: 85 },
          }}
        >
          {props.message}
        </Text>
        <FontIcon
          iconName={props.iconName}
          style={{ fontSize: 125, color: "lightgray" }}
        />
      </div>
    </div>
  );
};

export default LoadingSign;

import { useEffect, useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  IGroup,
  IGroupInUser,
  ITenant,
  createUser,
  editUser,
  getGroups,
  getTenants,
  getUser,
} from "../../services/assetServices";
import { v4 as uuid } from "uuid";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";

const User = () => {
  const isSaved = useRef(false);

  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [tenants, setTenants] = useState<ITenant[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        setGroups((await getGroups(abortController, 10, 1)).items);
        setTenants((await getTenants(abortController, 10, 1)).items);
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  return (
    <>
      <div>User</div>
      <EditItem
        getAction={async (abortController, id) => {
          const user: any = await getUser(abortController, id);
          user.groupsId = user.groups
            .map((group: IGroupInUser) => group.id)
            .join("|");
          user.defaultTenant = (user.defaultTenant ?? "").toString();
          return user;
        }}
        newAction={async (abortController, newUser) => {
          const newUserResponse = createUser(abortController, {
            username: newUser.username,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            groupsId: newUser.groupsId
              .split("|")
              .map((groupId: string) => Number.parseInt(groupId)),
            defaultTenantId: Number.parseInt(newUser.defaultTenant),
          });
          setSuccessMessage(
            `User with id: ${(await newUserResponse).userId} created.`
          );
        }}
        editAction={async (abortController, id, editedUser) => {
          await editUser(abortController, id, {
            username: editedUser.username,
            firstName: editedUser.firstName,
            lastName: editedUser.lastName,
            email: editedUser.email,
            groupsId: (editedUser.groupsId ?? "")
              .split("|")
              .map((groupId: string) => Number.parseInt(groupId)),
            defaultTenantId: Number.parseInt(editedUser.defaultTenant),
          });
          setSuccessMessage(`User with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/security?tab=users"}
        defaultValues={JSON.stringify({ uuid: uuid() })}
        metadata={{
          fields: [
            {
              name: "username",
              fieldType: "String",
              label: "User name",
            },
            {
              name: "firstName",
              fieldType: "String",
              label: "First name",
            },
            {
              name: "lastName",
              fieldType: "String",
              label: "Last name",
            },
            {
              name: "email",
              fieldType: "String",
              label: "Email",
            },
            {
              name: "groupsId",
              fieldType: "Lookup",
              label: "Groups",
              multiSelect: true,
              lookupList: "Groups",
            },
            {
              name: "defaultTenant",
              fieldType: "Lookup",
              label: "Tenant",
              lookupList: "Tenants",
            },
          ],
          lookups: [
            {
              name: "Groups",
              values: groups?.map((group) => ({
                key: group.id.toString(),
                value: group.name,
              })),
            },
            {
              name: "Tenants",
              values: tenants?.map((tenant) => ({
                key: tenant.id.toString(),
                value: tenant.name,
              })),
            },
          ],
          validations: [],
        }}
      />
    </>
  );
};

export default User;

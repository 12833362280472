import { DetailsList, Panel, PanelType, SelectionMode } from '@fluentui/react'
import { useAtomValue } from 'jotai';
import { profileDataAtom } from '../atoms/authAtoms';
import { ActionsNames } from '../actions';

export interface IMyPermissionsProps {
  isOpen: boolean;
  hideMyPermissions: any;
}

const MyPermissions = (props: IMyPermissionsProps) => {

  const profileData = useAtomValue(profileDataAtom);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideMyPermissions}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText={profileData.userPrincipalName}
    >
      <DetailsList
        items={profileData?.permissions?.map(p => ({
          name: ActionsNames[p]
        }))}
        columns={[{
          key: 'name',
          name: 'Permission name',
          fieldName: 'name',
          minWidth: 100,
        }]}
        selectionMode={SelectionMode.none} />
    </Panel>
  )
}

export default MyPermissions
import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import { createGroup, editGroup, getGroup } from "../../services/assetServices";
import { v4 as uuid } from "uuid";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";
import ContractorSelector from "../../common/selectors/ContractorSelector";
import { useBoolean } from "@fluentui/react-hooks";
import {
  CommandBarButton,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";

const Group = () => {
  const isSaved = useRef(false);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [contractorId, setContractorId] = useState<number>();

  const [
    isContractorSelectorOpen,
    { setTrue: showContractorSelector, setFalse: hideContractorSelector },
  ] = useBoolean(false);

  return (
    <>
      <div>Group</div>
      <EditItem
        getAction={async (abortController, id) => {
          const group: any = await getGroup(abortController, id);
          setContractorId(group.contractorId);
          return group;
        }}
        newAction={async (abortController, newGroup) => {
          const newGroupResponse = await createGroup(abortController, {
            name: newGroup.name,
            contractorId: contractorId,
          });
          setSuccessMessage(
            `Group with id: ${newGroupResponse.groupId} created.`
          );
        }}
        editAction={async (abortController, id, editedGroup) => {
          await editGroup(abortController, id, {
            name: editedGroup.name,
            contractorId: contractorId,
          });
          setSuccessMessage(`Group with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/security?tab=groups"}
        defaultValues={JSON.stringify({ uuid: uuid() })}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
          ],
          lookups: [],
          validations: [],
        }}
      >
        <Stack horizontal>
          <Label style={labelColumnStyle}>Contractor</Label>
          <TextField
            style={{ width: valueColumnStyle.width - 55 }}
            value={contractorId ? contractorId.toString() : ""}
            readOnly
            disabled
          />
          <CommandBarButton
            onClick={showContractorSelector}
            style={{ width: 55 }}
          >
            ...
          </CommandBarButton>
        </Stack>
      </EditItem>
      <ContractorSelector
        isOpen={isContractorSelectorOpen}
        hideContractorSelector={hideContractorSelector}
        onContractorSelected={(id: number) => {
          setContractorId(id);
          hideContractorSelector();
        }}
      />
    </>
  );
};

export default Group;

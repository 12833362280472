import { appRoles } from '../../authConfig';
import PagedList, { IPagedListRefType } from '../../common/PagedList'
import { deleteUser, getUsers } from '../../services/assetServices'
import { useAtomValue, useSetAtom } from 'jotai';
import { profileDataAtom } from '../../atoms/authAtoms';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { successMessageAtom } from '../../atoms/messageBarAtoms';

const Users = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const setSuccessMessage = useSetAtom(successMessageAtom);

  const [selectedUserId, setSelectedUserId] = useState<Number>();
  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <PagedList selectedTab='Users'
      ref={pagedListRef}
      columns={[
        { key: 'id' },
        { key: 'username' },
        { key: 'firstName' },
        { key: 'lastName' },
        { key: 'email', minWidth: 200 },
      ]}
      getAction={getUsers}
      commandBarItems={[
        {
          key: "newUser",
          text: "New",
          iconProps: { iconName: "Add" },
          onClick: () => navigate("/newUser"),
          disabled: !profileData.roles.includes(appRoles.Admin)
        },
        {
          key: "editUser",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: () => navigate(`/editUser?id=${selectedUserId}`),
          disabled: !profileData.roles.includes(appRoles.Admin) || (!selectedUserId)
        },
        {
          key: "deleteUser",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          onClick: () => {
            if (selectedUserId) {
              const abortController = new AbortController();
              deleteUser(abortController, (Number)(selectedUserId))
              .then(() => {
                pagedListRef.current?.refresh();
                setSuccessMessage(`User with id: ${selectedUserId} deleted.`);
              });
            }
          },
          disabled: !profileData.roles.includes(appRoles.Admin) || (!selectedUserId)
        },
      ]}
      onSelectionChange={(selection: any) => {setSelectedUserId((Number)(selection.id))}}
    ></PagedList>
  )
}

export default Users
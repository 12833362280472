import {
  DetailsList,
  Link,
  Panel,
  PanelType,
  SearchBox,
  SelectionMode,
} from "@fluentui/react";
import {
  getContractors,
  IPagedCollection,
  IContractor,
} from "../../services/assetServices";
import { useState } from "react";

export interface IContractorSelectorProps {
  isOpen: boolean;
  hideContractorSelector: any;
  onContractorSelected: (id: number) => void;
}

const ContractorSelector = (props: IContractorSelectorProps) => {
  const [contractors, setContractors] = useState<IContractor[]>([]);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideContractorSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick contractor for selected work order"
    >
      <SearchBox
        placeholder="Search contractor"
        onSearch={(newValue) => {
          const abortController = new AbortController();
          getContractors(abortController, 10, 1, newValue).then(
            (contractors: IPagedCollection<IContractor>) => {
              setContractors(contractors.items);
            }
          );
        }}
        onClear={() => setContractors([])}
      />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item) => (
              <Link
                key={item.id}
                onClick={() => {
                  props.onContractorSelected(item?.id);
                }}
              >
                {item.id}
              </Link>
            ),
          },
          {
            key: "Code",
            name: "Code",
            fieldName: "code",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
        ]}
        compact={true}
        items={contractors}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  );
};

export default ContractorSelector;

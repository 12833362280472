import { IField, IValidation } from "../services/assetServices";

export interface IValidationError {
  fieldName: string;
  message: string;
}
const validateRule = (value: string, rules: string[], filed: string, fieldType: string): string | undefined => {
  if (rules.find(r => r === "NE") && !value) {
    return `${filed}  can not be empty`;
  }
  const ngtRule = rules.find(r => r.startsWith('NGT'));
  if (ngtRule) {
    const max = Number.parseInt(ngtRule.replace('NGT', ''));
    if (fieldType === 'String') {
      if (value.length > max) {
        return `${filed} should not be longer than ${max} chars`
      }
    } else if (fieldType === 'Number') {
      if (Number.parseInt(value) > max) {
        return `${filed} should not be more than ${max}`
      }
    }
  }
  const nltRule = rules.find(r => r.startsWith('NLT'));
  if (nltRule) {
    const min = Number.parseInt(nltRule.replace('NLT', ''));
    if (fieldType === 'String') {
      if (value.length < min) {
        return `${filed} should not be shorter than ${min} chars`
      }
    } else if (fieldType === 'Number') {
      if (Number.parseInt(value) < min) {
        return `${filed} should not be less than ${min}`
      }
    }
  }
  return undefined;
}

export const validate = (entity: any, validations: IValidation[], otherInfoFields?: IField[]): IValidationError[] => {
  const errors: IValidationError[] = [];
  if (!entity) {
    return errors;
  }
  for (const rule of validations ?? []) {
    type IEntityProperties = keyof typeof entity;
    const fieldName: keyof typeof entity = rule.field as IEntityProperties;
    const value = Object.keys(entity).find(prop => prop === fieldName) ? entity[fieldName] : undefined;
    if (value) {
      const err = validateRule(value, rule.rules, rule.field, typeof (value) === 'number' ? 'Number' : 'String');
      if (err) {
        errors.push({ fieldName: fieldName.toString(), message: err });
      }
    } else {
      const otherInfoField = (otherInfoFields?.find(f => f.name === fieldName))
      if (otherInfoField) {
        const otherInfoValue = entity.otherInfo ? entity.otherInfo[fieldName] : undefined;
        const err = validateRule(otherInfoValue, rule.rules, rule.field, otherInfoField.fieldType);
        if (err) {
          errors.push({ fieldName: fieldName.toString(), message: err });
        }
      }
    }
  }
  return errors;
}
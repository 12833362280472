import {
  Stack,
  CommandBar,
  DetailsList,
  IconButton,
  SelectionMode,
  Panel,
  PanelType,
  TextField,
} from "@fluentui/react";
import { IFieldsTab } from "../../services/assetServices";
import { useBoolean } from "@fluentui/react-hooks";
import { useState } from "react";

export interface IFieldsTabsEditor {
  fieldsTab?: IFieldsTab[];
  onChange: (newValue: IFieldsTab[]) => void;
}

const FieldsTabsEditor = (props: IFieldsTabsEditor) => {
  const [
    isTabEditorPanelOpen,
    { setTrue: showTabEditorPanel, setFalse: hideTabEditorPanel },
  ] = useBoolean(false);
  const [fieldsTab, setFieldsTab] = useState<IFieldsTab[]>(
    props.fieldsTab ?? []
  );
  const [currentTab, setCurrentTab] = useState<IFieldsTab>();
  const [selectedRow, setSelectedRow] = useState<number>(0);

  return (
    <>
      <Stack>
        <CommandBar
          items={[
            {
              key: "AddValidation",
              text: "Add",
              iconProps: { iconName: "Add" },
              onClick: () => {
                setSelectedRow(0);
                showTabEditorPanel();
              },
            },
          ]}
        />
        <DetailsList
          columns={[
            {
              key: "tabName",
              name: "tabName",
              fieldName: "tabName",
              minWidth: 100,
            },
            {
              key: "caption",
              name: "Caption",
              fieldName: "caption",
              minWidth: 100,
            },
            {
              key: "action",
              name: "Action",
              minWidth: 85,
              onRender: (item: any, index?: number) => (
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "Edit" }}
                    onClick={() => {
                      setSelectedRow(index ?? 0);
                      setCurrentTab(item);
                      showTabEditorPanel();
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {}}
                  />
                </Stack>
              ),
            },
          ]}
          items={
            fieldsTab?.map((v, index) => ({
              ...v,
              index: index,
            })) ?? []
          }
          selectionMode={SelectionMode.none}
        />
      </Stack>
      <Panel
        isOpen={isTabEditorPanelOpen}
        onDismiss={() => hideTabEditorPanel}
        closeButtonAriaLabel="Close"
        type={PanelType.smallFixedFar}
        headerText={"Field Tab"}
      >
        <TextField
          label="Enter the key:"
          value={currentTab?.tabName}
          onChange={(_event, newValue?: string) => {
            setCurrentTab({
              tabName: newValue ?? "",
              order: currentTab?.order ?? 0,
              caption: currentTab?.caption ?? "",
            });
          }}
        />
        <TextField
          label="Enter the caption:"
          value={currentTab?.caption}
          onChange={(_event, newValue?: string) => {
            setCurrentTab({
              tabName: currentTab?.tabName ?? "",
              order: currentTab?.order ?? 0,
              caption: newValue ?? "",
            });
          }}
        />
        <Stack horizontal>
          <IconButton
            iconProps={{ iconName: "CheckMark" }}
            onClick={() => {
              const newTab = {
                tabName: currentTab?.tabName ?? "",
                order: fieldsTab.length + 1,
                caption: currentTab?.caption ?? "",
              };
              const newTabs = fieldsTab ?? [];
              if (selectedRow === 0) {
                newTabs?.push(newTab);
              } else {
                newTabs.splice(selectedRow, 1, newTab);
              }
              setFieldsTab(newTabs);
              props.onChange(newTabs);
              hideTabEditorPanel();
            }}
          />
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={hideTabEditorPanel}
          />
        </Stack>
      </Panel>
    </>
  );
};

export default FieldsTabsEditor;

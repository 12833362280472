import { ISettings, Link, Stack } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IAssetDetails,
  getAsset,
  getSettings,
  getZoneDetail,
} from "../services/assetServices";
import BackButton from "../common/BackButton";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../atoms/messageBarAtoms";

const ShowQR = () => {
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const query = new URLSearchParams(useLocation().search);
  const assetId = Number.parseInt(query.get("assetId") ?? "");
  const zoneId = query.get("zoneId");
  const assetUuid = useRef<string>();
  const settings = useRef<ISettings>();

  const getImageData = async (abortController: AbortController): Promise<string> => {
    if (assetId) {
      const data: IAssetDetails = await getAsset(abortController, assetId);
      assetUuid.current = data.asset.uuid;
      return `data:image/png;base64,${data.qrCode.data}`;
    } else if (zoneId) {
      const data = await getZoneDetail(abortController, zoneId || "");
      return `data:image/png;base64,${data.qrCode.data}`;
    }
    throw new Error();
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      setIsInProgress(true);
      try {
        settings.current = await getSettings(abortController);
        const img: string = await getImageData(abortController);
        setImage(img);
        setSuccessMessage("Download completed");
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  const [image, setImage] = useState("");

  return (
    <div>
      <Stack>
        <BackButton backTo={`${assetId ? "/" : "/zones"}`} />
        <div className="ms-Grid">
          <div className="ms-Grid-row">
            <img src={image} alt="" style={{ width: 150 }} />
          </div>
          <div className="ms-Grid-row">
            <span>
              {assetId ? `Asset id: ${assetId}` : `Zone id: ${zoneId}`}
            </span>
          </div>
        </div>
        <Link
          href={`${settings.current?.clientUrl}/?${assetId ? "code=" : "zone="
            }${assetId ? assetUuid.current : zoneId}`}
        >
          {assetId ? assetUuid.current : zoneId}
        </Link>
      </Stack>
    </div>
  );
};

export default ShowQR;

import {
  Link,
  Panel,
  PanelType,
  Separator,
  Stack,
  Text,
} from "@fluentui/react";
import { useAtom } from "jotai";
import { INotification, notificationsAtom } from "../atoms/notifAtoms";

export interface INotificationsProps {
  isOpen: boolean;
  hideNotifications: any;
}

const Notifications = (props: INotificationsProps) => {
  const [notifs, setNotifs] = useAtom(notificationsAtom);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideNotifications}
      closeButtonAriaLabel="Close"
      type={PanelType.smallFixedFar}
      headerText={"Notifications"}
    >
      <Stack>
        <p />
        {notifs.map((notif: INotification) => (
          <Stack>
            <Text>{notif.message ?? ""}</Text>
            <Link
              styles={{ root: { textAlign: "end" } }}
              onClick={() =>
                setNotifs(notifs.filter((n) => n.message !== notif.message))
              }
            >
              Dismiss
            </Link>
            <Separator />
          </Stack>
        ))}
      </Stack>
    </Panel>
  );
};

export default Notifications;

import React, { PropsWithChildren, useRef } from 'react'

export interface IPanelSplitter {
  firstPanelSize: number;
  secondPanelSize: number;
}

const PanelSplitter = (props: PropsWithChildren<IPanelSplitter>) => {
  var children = React.Children.toArray(props.children);

  const leftDiv = useRef<HTMLDivElement>(null);
  const rightDiv = useRef<HTMLDivElement>(null);
  const splitterDiv = useRef<HTMLDivElement>(null);

  let prevX = 0;
  let dragStarted = false;

  const mouseUpHandler = (ev: any) => {
    dragStarted = false;
  }
  const mouseMoveHandler = (ev: any) => {
    if (!dragStarted) {
      return;
    }
    console.log(ev);
    const deltaX = ev.clientX - prevX;
    if (leftDiv.current?.style.width) {
      leftDiv.current.style.width = `${Number.parseInt(leftDiv.current.style.width) + (deltaX)}px`;
    }
    if (rightDiv.current?.style.width) {
      rightDiv.current.style.width = `${Number.parseInt(rightDiv.current.style.width) - (deltaX)}px`;
    }
    prevX = ev.clientX;
  }
  return (
    <>
      <div ref={leftDiv} style={{ width: props.firstPanelSize }}
        onMouseUp={mouseUpHandler} onMouseMove={mouseMoveHandler}>
        {children[0]}
      </div>
      <div ref={splitterDiv}
        style={{ width: 5, backgroundColor: 'lightgray', position: 'relative', left: 0, top: 0, zIndex: 9999, cursor: 'col-resize' }}
        onMouseDown={(ev) => {
          ev.preventDefault();
          dragStarted = true;
          prevX = ev.clientX;
        }}
        onMouseUp={mouseUpHandler}
      ></div>
      <div ref={rightDiv} style={{ width: props.secondPanelSize }}
        onMouseUp={mouseUpHandler} onMouseMove={mouseMoveHandler}>
        {children[1]}
      </div>
    </>
  )
}

export default PanelSplitter
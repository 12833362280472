import {
  getDashboardPanelOptions,
  getDashboardPanels,
} from "../../services/assetServices";
import CountCardPanel from "./CountCardPanel";

const IssuesCountCardPanel: React.FC = () => {
  const fetchOptionsFunction = async () => {
    const optionsResponse = await getDashboardPanelOptions(
      new AbortController(),
      "IssuesCount"
    );
    const options = Object.entries(optionsResponse.values).map(
      ([name, value]) => ({
        key: name,
        text: value,
      })
    );
    return options;
  };

  const fetchDataFunction = async (status: string) => {
    return await getDashboardPanels(
      new AbortController(),
      "IssuesCount",
      status
    );
  };

  return (
    <CountCardPanel
      label="Issues"
      fetchOptionsFunction={fetchOptionsFunction}
      fetchDataFunction={fetchDataFunction}
    />
  );
};
export default IssuesCountCardPanel;

import { getLogFile } from "../services/assetServices";

export const downloadFile = (logFile: string) => {

  const abortController = new AbortController();

  getLogFile(abortController, logFile)
    .then((docContent) => {
      docContent.blob().then((docBlob) => {
        const docUrl = URL.createObjectURL(docBlob);
        const anchor = document.createElement("a");
        anchor.href = docUrl;
        anchor.download = "ImportLog.txt";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(docUrl);
      })
    })
    .catch((error: any) => {
      throw new Error(error && error["Message"] ? error.Message : "");
    });
}
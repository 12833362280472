import { useContext, useEffect, useRef, useState } from "react";
import PagedList, {
  IListPageColumn,
  IPagedListRefType,
} from "../../common/PagedList";
import {
  IWorkOrderStatuses,
  getContractors,
  getMetadata,
  getWorkOrderStatuses,
} from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { profileDataAtom } from "../../atoms/authAtoms";
import {
  clearMessageAtom,
  errorMessageAtom,
  isInProgressAtom,
} from "../../atoms/messageBarAtoms";
import { appRoles } from "../../authConfig";
import AppContext from "../AppContext";
import { StackedBarChart } from "@fluentui/react-charting";

const ContractorsV2 = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();

  const [selectedContractorId, setSelectedContractorId] = useState<number>();

  const [workOrderStatuses, setWorkOrderStatuses] = useState<
    IWorkOrderStatuses[]
  >([]);
  const pagedListRef = useRef<IPagedListRefType>(null);

  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const columns = useRef<IListPageColumn[]>([]);

  const fetchData = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);

    try {
      const abortController = new AbortController();

      const workOrderStatuses: IWorkOrderStatuses[] =
        await getWorkOrderStatuses(abortController);

      setWorkOrderStatuses(workOrderStatuses);
      const metadata = await getMetadata(abortController, "Contractor");
      const otherInfoFields = metadata?.fields.filter((mf) => mf.showInGrid);
      otherInfoFields?.forEach((mf) => {
        if (!columns.current.find((c) => c.key === mf.name)) {
          columns.current.push({
            key: mf.name,
            name: mf.name,
            minWidth: 100,
            onRender:
              mf.fieldType !== "Image"
                ? undefined
                : (item: any) => (
                    <img src={item[mf.name]} style={{ width: 20 }} alt="" /> //:
                  ),
          });
        }
      });
      clearMessage();
      context.setSelectedTab("Contractors");
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PagedList
        selectedTab="Contractors"
        ref={pagedListRef}
        listHeigh={525}
        columns={[
          {
            key: "id",
            name: "Id",
            minWidth: 25,
            maxWidth: 50,
            isResizable: true,
          },
          {
            key: "name",
            name: "Name",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
          {
            key: "code",
            name: "Code",
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
          },
          {
            key: "workOrders",
            name: "WorkOrders",
            minWidth: 80,
            maxWidth: 120,
            isResizable: false,
            // className: 'chartRowCell',
            onRender: (item, index, column) => {
              const fills = new Map<string, string>();
              for (const wos of workOrderStatuses ?? []) {
                fills.set(wos.code, wos.color);
              }

              return item.workOrders ? (
                <StackedBarChart
                  data={{
                    chartTitle: "",
                    chartData: Object.keys(item.workOrders)?.map(
                      (key: string) => ({
                        legend: key,
                        data: Number.parseInt(item.workOrders[key]),
                        color: fills.get(key),
                      })
                    ),
                  }}
                  hideNumberDisplay={true}
                  hideLegend={true}
                />
              ) : (
                <span>No workOrders</span>
              );
            },
          },
          {
            key: "createdAt",
            name: "Created at",
            minWidth: 125,
            maxWidth: 155,
            isResizable: true,
          },
          ...columns.current,
        ]}
        getAction={async (abortController, pageSize, pageNo) => {
          const data = await getContractors(abortController, pageSize, pageNo);

          data.items = data.items.map((contractor) => {
            const newContractor: any = { ...contractor };
            const otherInfoParts = contractor.otherInfo.split("|");
            columns.current.map((mf, index) => {
              if (mf?.name) {
                const part = otherInfoParts[index];
                newContractor[mf.name] = part;
                if (part.includes(".blob.core.windows.net/attachments/")) {
                  newContractor[mf.name] = `${part.replace(
                    "attachments",
                    "thumbnails"
                  )}.png`;
                }
              }
            });
            return newContractor;
          });
          return data;
        }}
        commandBarItems={[
          {
            key: "newContractor",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => navigate("/newContractor"),
            disabled: !profileData.roles.includes(appRoles.Admin),
          },
          {
            key: "editContractor",
            text: "Edit",
            iconProps: { iconName: "Edit" },
            onClick: () =>
              navigate(`/editContractor?id=${selectedContractorId}`),
            disabled:
              !profileData.roles.includes(appRoles.Admin) ||
              !selectedContractorId,
          },
        ]}
        onSelectionChange={(selection: any) => {
          setSelectedContractorId(Number(selection.id));
        }}
      ></PagedList>
    </>
  );
};

export default ContractorsV2;

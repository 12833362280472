import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  IContractor,
  ICreateContractorRequest,
  IMetadata,
  IUpdateContractorRequest,
  IGroup,
  createContractor,
  editContractor,
  getContractor,
  getGroup,
  getMetadata,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  IconButton,
  Label,
  Separator,
  Stack,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import WorkOrdersV2 from "../workOrders/WorkOrdersV2";
import { useLocation } from "react-router-dom";
import OtherInfo from "../../common/OtherInfo";
import UserOrGroupSelector from "../../common/selectors/UsersOrGroupSelectors";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";

const ContractorV2 = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("id") ?? "");

  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const [contractorId, setContractorId] = useState<number>(itemId);
  const [group, setGroup] = useState<IGroup>();
  const [workOrdersIds, setWorkOrdersIds] = useState<number[]>();
  const setSuccessMessage = useSetAtom(successMessageAtom);

  const [
    isUserSelectorOpen,
    { setTrue: showUserSelector, setFalse: hideUserSelector },
  ] = useBoolean(false);

  const isSaved = useRef(false);
  
  return (
    <>
      <EditItem
        getAction={async (abortController, id) => {
          const contractor: any = await getContractor(abortController, id);
          setContractorId(id);
          if (contractor.groupId) {
            const group = await getGroup(abortController, contractor.groupId);
            setGroup({
              id: contractor.groupId,
              name: group.name,
            });
          }
          const metadata = await getMetadata(abortController, "Contractor");
          setOtherInfo(JSON.parse(contractor.otherInfo));
          setMetadata(metadata);
          setWorkOrdersIds(contractor.workOrdersIds);
          contractor.statusId = (contractor.statusId ?? "").toString();
          return contractor;
        }}
        newAction={async (abortController, newWorkOrder) => {
          const data: ICreateContractorRequest = {
            code: newWorkOrder.code,
            name: newWorkOrder.name,
            workOrdersIds: workOrdersIds ?? [],
            // workOrdersIds: (workOrdersInput?.current as unknown as IBasePicker<ITag>)
            //   .items?.map(item => (Number)(item.key)) ?? [],
            otherInfo: JSON.stringify(otherInfo),
            assignmentsDescription: newWorkOrder.assignmentsDescriptionInput,
            groupId: group?.id,
          };
          const newContractorResponse = await createContractor(
            abortController,
            data
          );
          setSuccessMessage(
            `Contractor with id: ${newContractorResponse.contractorId} created.`
          );
        }}
        editAction={async (abortController, id, editedWorkOrder) => {
          const data: IUpdateContractorRequest = {
            name: editedWorkOrder.name,
            code: editedWorkOrder.code,
            workOrdersIds: workOrdersIds ?? [],
            otherInfo: JSON.stringify(otherInfo),
            groupId: group?.id,
          };
          await editContractor(abortController, id, data);
          setSuccessMessage(`Contractor with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/contractors"}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
            {
              name: "code",
              fieldType: "String",
              label: "Code",
            },
          ],
          lookups: [],
          validations: [],
        }}
      >
        <>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Group</Label>
            <TextField
              style={valueColumnStyle}
              value={group?.name?.toString()}
            />
            <IconButton
              iconProps={{ iconName: "Search" }}
              onClick={() => showUserSelector()}
            />
            <UserOrGroupSelector
              userOrGroupOnly={"group"}
              isOpen={isUserSelectorOpen}
              onUserOrGroupSelected={(
                id: number,
                isGroup: boolean,
                username?: string
              ) => {
                setGroup({
                  id,
                  name: username ?? "",
                });
              }}
              hideUsersAndGroupsSelector={hideUserSelector}
            />
          </Stack>

          {metadata ? (
            <OtherInfo
              metadata={metadata}
              otherInfo={JSON.stringify(otherInfo)}
              isSaved={isSaved}
              onOtherInfoChanged={(newValue: string) => setOtherInfo(newValue)}
            />
          ) : (
            <></>
          )}
          <Separator>Assignments</Separator>
          <WorkOrdersV2
            contractorId={contractorId}
            hideEditButtons={true}
            hideExtraColumns={true}
          />
        </>
      </EditItem>
    </>
  );
};

export default ContractorV2;

import { IconButton, IIconProps, mergeStyles } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

export interface BackButtonParams {
  backTo?: string;
}
const BackButton = (params: BackButtonParams) => {
  const navigate = useNavigate();

  const iconClass = mergeStyles({
    fontSize: 14,
    height: 18,
    width: 18,
    padding: "8px 10px 0 10px",
  });
  const backIconProps: IIconProps = { iconName: "ChromeBack" };

  return (
    <IconButton
      iconProps={backIconProps}
      className={iconClass}
      onClick={() => navigate(params.backTo ?? "/")}
    />
  );
}

export default BackButton;
